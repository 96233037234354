import React, { useState, useEffect, useContext } from 'react';
import Aside from './Aside';
import PostList from './PostList';
import PostModal from './PostModal';
import CreatePost from './CreatePost';
import useWindowSize from '../hooks/useWindowSize';
import BottomNav from './BottomNav';
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { motion } from "framer-motion";
import RightSidebar from "./RightSidebar";

function Home() {
    const { isLoggedIn, user } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [posts, setPosts] = useState([]);
    const [showCreateButton, setShowCreateButton] = useState(true); // Initially visible
    const { width } = useWindowSize();
    const [userData, setUserData] = useState(user);

    useEffect(() => {
        const fetchUserData = async () => {
            if (isLoggedIn && !userData) {
                const token = localStorage.getItem('token');
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setUserData(response.data);
                } catch (error) {
                    console.error('Ошибка при загрузке данных пользователя!', error);
                }
            }
        };

        fetchUserData();
    }, [isLoggedIn, userData]);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden'; // Lock scroll
        } else {
            document.body.style.overflow = 'auto'; // Unlock scroll
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const addPost = (newPost) => {
        setPosts([newPost, ...posts]);
    };

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                setShowCreateButton(false); // Hide button on scroll down
            } else {
                setShowCreateButton(true); // Show button on scroll up
            }
            lastScrollY = window.scrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="container mx-auto flex flex-col lg:flex-row gap-6 p-2">
            {width >= 1024 ? (
                <Aside className="w-full lg:w-1/4" />
            ) : (
                <BottomNav />
            )}

            <main className="w-full lg:w-3/4 dark:bg-neutral-900 mt-4">
                <header className="mb-6">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="flex items-center justify-between mb-2 flex-1"
                    >
                        <h2 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">Главная</h2>
                        <span className="text-fuchsia-500 font-semibold text-xs border-2 border-fuchsia-500 px-3 py-1 rounded-full justify-end">
                            Layout
                        </span>
                    </motion.div>
                    <div className="flex items-center mb-4 dark:text-white overflow-auto">
                        {Array(10).fill('').map((_, i) => (
                            <div className="justify-center items-center mr-2 flex-shrink-0" key={i} role="button">
                                <img
                                    src="https://static.vecteezy.com/system/resources/thumbnails/027/951/137/small_2x/stylish-spectacles-guy-3d-avatar-character-illustrations-png.png"
                                    alt="Profile"
                                    className="w-16 h-16 bg-neutral-200 rounded-full mr-2 border-2 border-violet-400"
                                />
                                <p className="text-sm font-semibold w-14 ml-2.5 text-gray-900 dark:text-white">User {i + 1}</p>
                            </div>
                        ))}
                    </div>
                </header>

                {/* Floating action button for post creation, responsive between 768px and 1024px */}
                {width < 768 && (
                    <motion.button
                        onClick={openModal}
                        className={`fixed bottom-20 right-5 flex items-center justify-center bg-violet-500 hover:bg-violet-600 text-white font-semibold w-12 h-12 rounded-full shadow-lg transition-all duration-500 transform ${
                            showCreateButton ? 'opacity-100 scale-100' : 'opacity-0 scale-75'
                        } z-30`}
                        initial={{ scale: 0 }}
                        animate={{ scale: showCreateButton ? 1 : 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <i className="fas fa-plus text-xl"></i>
                    </motion.button>
                )}

                <PostList posts={posts} setPosts={setPosts} />

                {/* Floating button specifically for screens 768px - 1024px */}
                {width >= 768 && width < 1024 && (
                    <motion.button
                        onClick={openModal}
                        className="fixed bottom-8 right-8 flex items-center justify-center bg-violet-500 hover:bg-violet-600 text-white font-semibold w-10 h-10 rounded-full shadow-lg transition-all duration-300 transform hover:scale-105 z-20"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <i className="fas fa-plus text-lg"></i>
                    </motion.button>
                )}

                {width >= 1024 && (
                    <motion.button
                        onClick={openModal}
                        className="fixed bottom-10 right-10 flex items-center justify-center bg-violet-500 hover:bg-violet-600 text-white font-semibold px-4 py-2.5 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 z-20"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <i className="fas fa-plus text-xl"></i>
                    </motion.button>
                )}
            </main>

            {width > 1024 && <RightSidebar />}
            {isModalOpen && (
                <PostModal closeModal={closeModal}>
                    <CreatePost addPost={addPost} closeModal={closeModal} />
                </PostModal>
            )}
        </div>
    );
}

export default Home;
