import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";

const Notifications = () => {
    const { width } = useWindowSize();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch notifications on component mount
    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setNotifications(response.data.data);
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        } finally {
            setLoading(false);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}`, { notification_ids: [notificationId] }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setNotifications(notifications.map(notification =>
                notification.id === notificationId
                    ? { ...notification, read_at: new Date().toISOString() }
                    : notification
            ));
        } catch (error) {
            console.error("Failed to mark notification as read:", error);
        }
    };

    const deleteNotification = async (notificationId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setNotifications(notifications.filter(notification => notification.id !== notificationId));
        } catch (error) {
            console.error("Failed to delete notification:", error);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <Link to='/' className="cursor-pointer text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">
                <i className="fas fa-chevron-left mr-2"></i>Назад
            </Link>
            <h2 className="text-3xl font-extrabold mb-4 mt-2 dark:text-white">Уведомления</h2>

            {loading ? (
                <p className="text-gray-500">Загрузка...</p>
            ) : (
                <ul className="list-disc pl-5">
                    {notifications.length > 0 ? (
                        notifications.map(notification => (
                            <li key={notification.id} className={`mb-4 p-4 rounded ${notification.read_at ? 'bg-gray-100' : 'bg-violet-100'}`}>
                                <div className="flex justify-between items-center">
                                    <p className="text-gray-800 dark:text-gray-200">{notification.data.content}</p>
                                    <div className="flex space-x-2">
                                        {!notification.read_at && (
                                            <button
                                                onClick={() => markAsRead(notification.id)}
                                                className="text-blue-500 hover:text-blue-700"
                                            >
                                                Отметить как прочитанное
                                            </button>
                                        )}
                                        <button
                                            onClick={() => deleteNotification(notification.id)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                </div>
                                <p className="text-xs text-gray-500 mt-2">{new Date(notification.created_at).toLocaleString()}</p>
                            </li>
                        ))
                    ) : (
                        <p className="text-gray-500">Нет новых уведомлений.</p>
                    )}
                </ul>
            )}

            {width < 768 && <BottomNav />}
        </div>
    );
};

export default Notifications;
