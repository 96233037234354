import React from 'react';

function AvatarGallery({ avatars, onImageClick }) {
    return (
        <div className="max-w-md mx-auto p-4 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 dark:text-white">Фото</h3>
            <div className="grid grid-cols-3 gap-4">
                {avatars.map((avatar, index) => (
                    <img
                        key={index}
                        src={`${process.env.REACT_APP_BASE_URL}/storage/${avatar.avatar_path}`}
                        alt={`Old Avatar ${index + 1}`}
                        className="rounded-lg shadow-lg transform transition duration-200 cursor-pointer"
                        onClick={() => onImageClick(`${process.env.REACT_APP_BASE_URL}/storage/${avatar.avatar_path}`)}
                    />
                ))}
            </div>
        </div>
    );
}

export default AvatarGallery;
