import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import AddFriend from './AddFriend';
import FriendRequests from './FriendRequests';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import useWindowSize from '../../hooks/useWindowSize';
import Echo from 'laravel-echo';
import BackLink from "../BackLink";
import FriendSuggestions from "./FriendSuggestions";

function FriendList() {
  const [friends, setFriends] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [friendToDelete, setFriendToDelete] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const fetchFriends = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке друзей!', error);
    }
  };

  const openModal = (friendId) => {
    setFriendToDelete(friendId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFriendToDelete(null);
  };

  const handleDeleteFriend = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/friends/${friendToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(friends.filter(friend => friend.friend.id !== friendToDelete));
      closeModal();
    } catch (error) {
      console.error('Ошибка при удалении друга!', error);
    }
  };

  const handleViewProfile = (friendId) => {
    navigate(`/profile/${friendId}`);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchFriends();

      if (window.Echo) {
        window.Echo.join('users')
            .here(users => {
              setFriends(friends => friends.map(friend => {
                const isOnline = users.find(user => user.id === friend.friend.id);
                return { ...friend, is_online: !!isOnline };
              }));
            })
            .joining(user => {
              setFriends(friends => friends.map(friend => {
                if (friend.friend.id === user.id) {
                  return { ...friend, is_online: true };
                }
                return friend;
              }));
            })
            .leaving(user => {
              setFriends(friends => friends.map(friend => {
                if (friend.friend.id === user.id) {
                  return { ...friend, is_online: false };
                }
                return friend;
              }));
            });
      }
    }
  }, [isLoggedIn]);


  return (
      <div className="container mx-auto p-4 dark:bg-neutral-900 h-screen">
        <BackLink />
        <h2 className="text-3xl font-extrabold mb-6 dark:text-white">Друзья</h2>
        <AddFriend fetchFriends={fetchFriends} />
        <FriendSuggestions />
        <FriendRequests />
        <ul className="space-y-4 mt-4">
          {friends.length === 0 ? (
              <p className="text-gray-600 dark:text-gray-300 text-center">У вас нет друзей</p>
          ) : (
              friends.map(friend => (
                  <li key={friend.id}
                      className="bg-white dark:bg-neutral-800 p-4 rounded-lg shadow-md flex items-center space-x-4 relative">
                    <img
                        src={friend.friend.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}` : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"}
                        alt={friend.friend.name || "Profile"}
                        className="w-16 h-16 rounded-full object-cover"
                    />
                    <div className="flex-1">
                      <p className="text-[clamp(16px,1.5vw,20px)] font-semibold dark:text-white">{friend.friend.name || "Неизвестный пользователь"}</p>
                      <span className="text-[clamp(12px,1.5vw,14px)] flex items-center space-x-1">
      <i className={`fas ${friend.is_online ? 'fa-circle text-green-500' : 'fa-circle text-red-500'}`}></i>
      <span className="text-gray-600 dark:text-gray-300">
        {friend.is_online ? 'Online' : 'Offline'}
      </span>
    </span>
                      <p className="text-[clamp(12px,1.5vw,14px)] text-gray-600 dark:text-gray-300">{friend.friend.email || "Email неизвестен"}</p>
                    </div>
                    <div className="flex-shrink-0 space-x-2 flex">
                      {width < 768 ? (
                          <div className="flex space-x-2">
                            <button
                                onClick={() => handleViewProfile(friend.friend.id)}
                                className="bg-violet-300 text-violet-800 w-8 h-8 rounded-xl hover:bg-violet-500 transition duration-200 flex items-center justify-center"
                            >
                              <i className="fas fa-user"></i>
                            </button>
                            <button
                                onClick={() => openModal(friend.friend.id)}
                                className="bg-red-500 text-red-800 w-8 h-8 rounded-xl hover:bg-red-600 transition duration-200 flex items-center justify-center"
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </div>
                      ) : (
                          <>
                            <button
                                onClick={() => handleViewProfile(friend.friend.id)}
                                className="text-violet-500 dark:text-violet-400 hover:text-violet-600 dark:hover:text-violet-300 transition duration-200"
                            >
                              Профиль
                            </button>
                            <button
                                onClick={() => openModal(friend.friend.id)}
                                className="text-red-500 dark:text-red-400 hover:text-red-600 dark:hover:text-red-300 transition duration-200"
                            >
                              Удалить
                            </button>
                          </>
                      )}
                    </div>
                  </li>

              ))
          )}
        </ul>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Подтверждение удаления"
            className="fixed inset-0 flex items-center justify-center"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <div className="bg-white dark:bg-neutral-800 p-8 rounded-lg shadow-lg w-full max-w-sm">
            <h2 className="text-lg font-semibold mb-4 dark:text-white">Вы уверены, что хотите удалить друга?</h2>
            <div className="flex justify-end space-x-2">
              <button
                  onClick={closeModal}
                  className="bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-4 py-2 rounded-lg hover:bg-gray-400 dark:hover:bg-gray-600 transition duration-200"
              >
                Отмена
              </button>
              <button
                  onClick={handleDeleteFriend}
                  className="bg-red-500 dark:bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-600 dark:hover:bg-red-700 transition duration-200"
              >
                Удалить
              </button>
            </div>
          </div>
        </Modal>
      </div>
  );
}

export default FriendList;
