import React, { useRef, useState, useEffect } from 'react';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExpand, FaForward, FaBackward } from 'react-icons/fa';
import useWindowSize from '../hooks/useWindowSize';

function CustomVideoPlayer({ videoUrl }) {
    const videoRef = useRef(null);
    const { width } = useWindowSize();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [isFocused, setIsFocused] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(true);
    const [hasStarted, setHasStarted] = useState(false);
    const [controlsVisible, setControlsVisible] = useState(true);

    useEffect(() => {
        const video = videoRef.current;
        const updateProgress = () => {
            setCurrentTime(video.currentTime);
            setProgress((video.currentTime / video.duration) * 100 || 0);
        };
        const handleLoadedMetadata = () => setDuration(video.duration);
        video.addEventListener('timeupdate', updateProgress);
        video.addEventListener('loadedmetadata', handleLoadedMetadata);
        return () => {
            video.removeEventListener('timeupdate', updateProgress);
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (isPlaying) video.pause();
        else {
            video.play();
            setShowPlayButton(false);
            setHasStarted(true);
        }
        setIsPlaying(!isPlaying);
    };

    const handleMuteUnmute = () => {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    const handleFullScreen = () => videoRef.current.requestFullscreen && videoRef.current.requestFullscreen();

    const handleProgressChange = (e) => {
        const newTime = (e.target.value / 100) * videoRef.current.duration;
        videoRef.current.currentTime = newTime;
        setProgress(e.target.value);
    };

    const handleForward = () => videoRef.current.currentTime = Math.min(videoRef.current.duration, videoRef.current.currentTime + 10);
    const handleBackward = () => videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 10);

    const handlePlaybackRateChange = (rate) => {
        videoRef.current.playbackRate = rate;
        setPlaybackRate(rate);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        const handleKeydown = (e) => {
            if (!isFocused) return;
            switch (e.code) {
                case 'Space': e.preventDefault(); handlePlayPause(); break;
                case 'ArrowRight': handleForward(); break;
                case 'ArrowLeft': handleBackward(); break;
                case 'KeyM': handleMuteUnmute(); break;
                case 'KeyF': handleFullScreen(); break;
                default: break;
            }
        };
        document.addEventListener('keydown', handleKeydown);
        return () => document.removeEventListener('keydown', handleKeydown);
    }, [isPlaying, isMuted, isFocused]);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const toggleControls = () => setControlsVisible(!controlsVisible);

    return (
        <div
            className="relative group max-w-full mx-auto overflow-hidden rounded-lg"
            tabIndex={0}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseMove={() => setControlsVisible(true)}
            onMouseLeave={() => setControlsVisible(false)}
        >
            <div className="relative w-full max-w-[900px] mx-auto aspect-w-16 aspect-h-9 lg:max-w-[600px] xl:max-w-[400px]">
                <div className="relative">
                    <video ref={videoRef} className="w-full h-full object-cover rounded-lg mb-4 " muted={isMuted}>
                        <source src={videoUrl} type="video/mp4" />
                        Ваш браузер не поддерживает видео.
                    </video>
                </div>
                {showPlayButton && (
                    <button
                        onClick={handlePlayPause}
                        className="absolute inset-0 flex justify-center items-center bg-black/60 text-white text-4xl hover:bg-black/80 transition duration-300 ease-in-out"
                    >
                        <FaPlay />
                    </button>
                )}
            </div>

            {hasStarted && (
                <div
                    className={`absolute bottom-0 left-0 right-0 flex flex-col space-y-3 px-4 py-3 bg-black/80 ${controlsVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 ease-in-out shadow-md rounded-t-lg`}
                >
                    <div className="relative">
                        <input
                            type="range"
                            className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-purple-400"
                            value={progress}
                            onChange={handleProgressChange}
                            style={{ background: `linear-gradient(to right, #6b46c1 ${progress}%, #ccc ${progress}%)` }}
                        />
                    </div>

                    <div className="flex justify-between text-gray-200 text-sm sm:text-base">
                        <span>{formatTime(currentTime)}</span>
                        <span>{formatTime(duration)}</span>
                    </div>

                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-3">
                            <button onClick={handleBackward} className="text-lg sm:text-xl text-gray-300 hover:text-purple-400 transition-colors duration-300">
                                <FaBackward />
                            </button>
                            <button onClick={handlePlayPause} className="text-xl sm:text-2xl text-gray-300 hover:text-purple-400 transition-colors duration-300">
                                {isPlaying ? <FaPause /> : <FaPlay />}
                            </button>
                            <button onClick={handleForward} className="text-lg sm:text-xl text-gray-300 hover:text-purple-400 transition-colors duration-300">
                                <FaForward />
                            </button>
                        </div>

                        <div className="flex items-center space-x-3">
                            <button onClick={handleMuteUnmute} className="text-xl sm:text-2xl text-gray-300 hover:text-purple-400 transition-colors duration-300">
                                {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                            </button>

                            {width > 768 && (
                                <div className="hidden sm:flex items-center space-x-1">
                                    {[0.5, 1, 1.5, 2].map(rate => (
                                        <button
                                            key={rate}
                                            onClick={() => handlePlaybackRateChange(rate)}
                                            className={`text-sm ${playbackRate === rate ? 'text-purple-400' : 'text-gray-300 hover:text-purple-400'} transition-colors duration-300`}
                                        >
                                            {rate}x
                                        </button>
                                    ))}
                                </div>
                            )}
                            <button onClick={handleFullScreen} className="text-xl sm:text-2xl text-gray-300 hover:text-purple-400 transition-colors duration-300">
                                <FaExpand />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CustomVideoPlayer;
