import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axios from 'axios';
import Pusher from 'pusher-js';
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";

function Aside() {
    const { isLoggedIn } = useContext(AuthContext);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const { width } = useWindowSize();

    const fetchUnreadMessages = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.warn('No token found');
                setUnreadMessagesCount(0);
                return;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/unread_count`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const unreadCount = response.data?.unread_count || 0;
            console.log("Fetched unread messages count:", unreadCount);
            setUnreadMessagesCount(unreadCount);
        } catch (error) {
            console.error('Error fetching unread messages count:', error);
            setUnreadMessagesCount(0);
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            fetchUnreadMessages();

            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, { cluster: 'eu' });
            const channel = pusher.subscribe('messages');

            const handleNewMessage = () => {
                console.log("New message event received");
                fetchUnreadMessages();
            };

            channel.bind('new-message', handleNewMessage);

            const intervalId = setInterval(() => {
                console.log("Polling for unread messages...");
                fetchUnreadMessages();
            }, 30000); // 30 секунд

            return () => {
                channel.unbind('new-message', handleNewMessage);
                pusher.unsubscribe('messages');
                clearInterval(intervalId);
            };
        }
    }, [isLoggedIn, fetchUnreadMessages]);

    if (width < 768) {
        return <BottomNav unreadMessagesCount={unreadMessagesCount} />;
    }
    return (
        <aside className="w-64 p-6 sticky top-0 h-screen overflow-y-auto overflow-x-hidden flex-shrink-0 flex flex-col">
            <ul className="space-y-6">
                <li>
                    <Link to="/profile" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-user mr-3 text-xl"></i>
                        <span className="font-semibold">Профиль</span>
                    </Link>
                </li>
                <li>
                    <Link to="/messages"
                          className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-message mr-3 text-xl"></i>
                        <div className="flex items-center justify-between w-full">
                            <span className="font-semibold">Сообщения</span>
                            {unreadMessagesCount > 0 && (
                                <span
                                    className="bg-red-500 text-white text-xs w-5 h-5 flex justify-center items-center rounded-full">
                                {unreadMessagesCount}
                            </span>
                            )}
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/posts/all"
                          className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-list mr-3 text-xl"></i>
                        <span className="font-semibold">Посты</span>
                    </Link>
                </li>
                <li>
                    <Link to="/friends" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-user-group mr-3 text-xl"></i>
                        <span className="font-semibold">Друзья</span>
                    </Link>
                </li>
                <li>
                    <Link to="/notifications" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-bell mr-3 text-xl"></i>
                        <span className="font-semibold">Уведомления</span>
                    </Link>
                </li>
                <li>
                    <Link to="/search" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-search mr-3 text-xl"></i>
                        <span className="font-semibold">Поиск</span>
                    </Link>
                </li>
                <li>
                    <Link to="/groups" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fa-regular fa-compass mr-3 text-xl"></i>
                        <span className="font-semibold">Группы</span>
                    </Link>
                </li>
                <li>
                    <Link to="/music" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-music mr-3 text-xl"></i>
                        <span className="font-semibold">Музыка</span>
                    </Link>
                </li>
                <li>
                    <Link to="/services" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-globe mr-3 text-xl"></i>
                        <span className="font-semibold">Сервисы</span>
                    </Link>
                </li>
                <li>
                    <Link to="/settings" className="flex items-center text-gray-700 hover:text-purple-600 dark:text-white dark:hover:text-purple-400 transition duration-300">
                        <i className="fas fa-cog mr-3 text-xl"></i>
                        <span className="font-semibold">Настройки</span>
                    </Link>
                </li>
            </ul>
        </aside>
    );
}

export default Aside;
