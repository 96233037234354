import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {format, formatDate} from 'date-fns';
import Modal from './Modal';
import EditPostModal from './EditPostModal';
import toast from "react-hot-toast";
import SharePostModal from './SharePostModal';
import useWindowSize from "../hooks/useWindowSize";
import DeletePost from "./DeletePost";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { useSwipeable } from 'react-swipeable';
import { motion } from "framer-motion";
import { Pagination } from "swiper/modules";
import './CustomSwiperStyles.css';
import {ClipLoader} from "react-spinners";
import CustomVideoPlayer from "./CustomVideoPlayer";
import { formatDistanceToNow, formatRelative } from 'date-fns';
import {ru} from "date-fns/locale";
import {Link, useNavigate} from "react-router-dom";

const notify = () => toast.error("Произошла ошибка!");

function Post({ post, fetchPosts, handleSave }) {
    const [posts, setPosts] = useState([]);
    const [postState, setPostState] = useState(post);
    const [loading, setLoading] = useState(true);
    const [comment, setComment] = useState('');
    const [showComments, setShowComments] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [contextMenu, setContextMenu] = useState({ postId: null, commentId: null, x: 0, y: 0 });
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [likes, setLikes] = useState(post.likes);
    const [comments, setComments] = useState(post.comments);
    const [views, setViews] = useState(post.views || 0);
    const contextMenuRef = useRef(null);
    const { width } = useWindowSize();
    const defaultAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';
    const hasViewed = useRef(false);
    const modalRef = useRef(null);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [contextMenuCommentId, setContextMenuCommentId] = useState(null);
    const [showDeleteCommentConfirm, setShowDeleteCommentConfirm] = useState(false);
    const [replyingTo, setReplyingTo] = useState(null);
    const [commentContent, setCommentContent] = useState('');
    const [expandedComments, setExpandedComments] = useState({});
    const [isClosing, setIsClosing] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const characterLimit = 200;

    const toggleExpanded = () => setIsExpanded(!isExpanded);

    const hideContextMenu = () => {
        setIsClosing(true);  // Устанавливаем флаг для анимации закрытия
        setTimeout(() => {
            setContextMenu({ postId: null, x: 0, y: 0 });
            setIsClosing(false);  // Сбрасываем флаг после завершения анимации
        }, 300);  // Убедитесь, что время совпадает с длительностью анимации
    };


    const toggleExpand = (commentId) => {
        setExpandedComments((prev) => ({
            ...prev,
            [commentId]: !prev[commentId],
        }));
    };

    const [loadingImages, setLoadingImages] = useState(
        Array(post.images.length).fill(true)
    );

    const handleImageLoad = (index) => {
        setLoadingImages((prev) => {
            const newLoadingState = [...prev];
            newLoadingState[index] = false;
            return newLoadingState;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setContextMenu({ postId: null, commentId: null, x: 0, y: 0 });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        // Cleanup event listeners on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [post.id]);

    useEffect(() => {
        const trackView = async () => {
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/posts/${post.id}/view`, {}, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } catch (error) {
                console.error('Error tracking post view:', error);
            }
        };

        trackView();
    }, [post.id]);

    const handleAddComment = async (newComment) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts/${post.id}/comment`, {
                content: newComment
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            const createdComment = response.data;
            setComments([...comments, createdComment]);
        } catch (error) {
            console.error('There was an error adding the comment!', error);
        }
    };

    const handleLikeComment = async (commentId) => {
        try {
            const userId = parseInt(localStorage.getItem('user_id'), 10);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/like/${commentId}/comment`, {
                user_id: userId
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.data.status === 'liked') {
                setComments(prevComments =>
                    prevComments.map(comment =>
                        comment.id === commentId
                            ? { ...comment, likes: [...comment.likes, { user_id: userId }] }
                            : comment
                    )
                );
            } else if (response.data.status === 'unliked') {
                setComments(prevComments =>
                    prevComments.map(comment =>
                        comment.id === commentId
                            ? { ...comment, likes: comment.likes.filter(like => like.user_id !== userId) }
                            : comment
                    )
                );
            }
        } catch (error) {
            console.error('Error liking/unliking comment:', error);
        }
    };

    const handleUnlikeComment = async (commentId) => {
        try {
            const userId = parseInt(localStorage.getItem('user_id'), 10);
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/unlike/${commentId}/comment`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.data.status === 'unliked') {
                setComments(prevComments =>
                    prevComments.map(comment =>
                        comment.id === commentId
                            ? { ...comment, likes: comment.likes.filter(like => like.user_id !== userId) }
                            : comment
                    )
                );
            }
        } catch (error) {
            console.error('Error unliking comment:', error);
        }
    };

    const handleLike = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/like/${post.id}/post`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLikes([...likes, { user_id: parseInt(localStorage.getItem('user_id'), 10) }]);
        } catch (error) {
            console.error('There was an error liking the post!', error);
        }
    };

    const handleUnlike = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/like/${post.id}/post`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLikes(likes.filter(like => like.user_id !== parseInt(localStorage.getItem('user_id'), 10)));
        } catch (error) {
            console.error('There was an error unliking the post!', error);
        }
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/posts/${post.id}/comment`,
                {
                    content: commentContent,
                    parent_id: replyingTo
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            const createdComment = response.data;
            setComments([...comments, createdComment]);
            setCommentContent('');
            setReplyingTo(null);
        } catch (error) {
            console.error('Ошибка при добавлении комментария!', error);
        }
    };

    const handleCancelReply = () => {
        setReplyingTo(null);
        setCommentContent('');
    };

    const toggleComments = () => {
        setShowComments(!showComments);
    };

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const closeModal = () => {
        setSelectedImageIndex(null);
    };

    const nextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % post.images.length);
    };

    const prevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + post.images.length) % post.images.length);
    };

    const handleEditPost = () => {
        setShowEditModal(true);
        hideContextMenu();
    };

    const handleDeletePost = () => {
        setShowDeleteConfirm(true);
        hideContextMenu();
    };

    const deletePost = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log('Post deleted successfully');

            // Immediately update posts state by filtering out the deleted post
            setPosts((prevPosts) => prevPosts.filter(p => p.id !== post.id));
            setShowDeleteConfirm(false);
            window.location.reload();
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };
    const handleContextMenu = (e, postId) => {

        const x = width < 768 ? (e.touches ? e.touches[0]?.clientX : 0) : e.pageX;
        const y = width < 768 ? (e.touches ? e.touches[0]?.clientY : 0) : e.pageY;

        setContextMenu({ postId, x, y });
    };


    const handleLongPress = (e, postId) => {
        const x = e.touches ? e.touches[0].clientX : 0;
        const y = e.touches ? e.touches[0].clientY : 0;
        setContextMenu({ postId, x, y });
    };


    const handleSharePost = () => {
        setShowShareModal(true);
        hideContextMenu();
    };

    const sendPostToUser = async (userId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/messages`, {
                user_id: userId,
                content: post.content,
                post_id: post.id,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setShowShareModal(false);
            toast.success('Пост отправлен успешно!');
        } catch (error) {
            console.error('There was an error sending the post!', error);
            toast.error('Произошла ошибка при отправке поста.');
        }
    };

    const formatViews = (count) => {
        if (count < 1000) {
            return count;
        } else if (count < 1000000) {
            return `${(count / 1000).toFixed(1)}K`;
        } else {
            return `${(count / 1000000).toFixed(1)}M`;
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: nextImage,
        onSwipedRight: prevImage,

    });

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.9 }
    };

    const overlayVariants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    };

    const mobileMenuVariants = {
        open: { y: 0 },
        closed: { y: '100%' }
    };

    function onClose() {
        hideContextMenu();
    }

    const handleContextMenuClick = (commentId) => {
        setContextMenu(prevMenu => ({
            postId: null,
            commentId: prevMenu.commentId === commentId ? null : commentId,
            x: 0,
            y: 0
        }));
    };

    const handleSavePost = (updatedPost) => {
        setPostState(updatedPost);
    };

    const handleDeleteClick = (commentId) => {
        setSelectedCommentId(commentId);
        setShowDeleteCommentConfirm(true);

    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/comments/${selectedCommentId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setComments(comments.filter(comment => comment.id !== selectedCommentId));
            setShowDeleteCommentConfirm(false);
            setContextMenuCommentId(null);
        } catch (error) {
            toast.error('Вы можете удалить только свои комментарии');
            console.error('Error deleting comment:', error.response ? error.response.data : error.message);
        }
    };

    const [startTouchY, setStartTouchY] = useState(0);
    const [y, setY] = useState(0);

    const handleTouchStart = (e) => {
        setStartTouchY(e.touches[0].clientY);
        setIsClosing(false);
    };

    const handleTouchMove = (e) => {
        const touchY = e.touches[0].clientY;
        const movementY = touchY - startTouchY;
        if (movementY > 0) setY(movementY > 300 ? 300 : movementY); // Ограничение перемещения вниз
    };

    const handleTouchEnd = () => {
        if (y > 150) {
            // Если смещение больше 150, закрываем меню
            setIsClosing(true);
            setY(300);
            setTimeout(hideContextMenu, 300); // Задержка для завершения анимации
        } else {
            setY(0); // Возвращаем в исходное положение
        }
    }

    useEffect(() => {
        if (contextMenu.postId === post.id && width < 768) {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = '';
            };
        }
    }, [contextMenu.postId, post.id, width]);

    const renderComments = (comments, parentId = null, level = 0) => {
        return comments
            .filter(comment => comment.parent_id === parentId)
            .map(comment => {
                const hasReplies = comments.some(c => c.parent_id === comment.id);
                const parentComment = comments.find(c => c.id === comment.parent_id);

                return (
                    <motion.div
                        key={comment.id}
                        className={`space-y-4 ${level === 1 ? 'ml-8' : ''}`}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="flex items-start space-x-4 bg-neutral-50 dark:bg-neutral-900 p-3 rounded-lg transition duration-300">
                            <img
                                src={comment.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${comment.user.avatar}` : defaultAvatar}
                                alt="Avatar"
                                className="w-8 h-8 rounded-full object-cover"
                            />
                            <div className="flex-1">
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-gray-900 dark:text-white">{comment.user.name}</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                        {formatRelative(new Date(comment.created_at), new Date(), {locale: ru})}
                                    </p>
                                </div>
                                {parentComment && (
                                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                                        Ответ на комментарий <Link to={`/profile/${parentComment.user.id}`} className="text-violet-600">{parentComment.user.name}</Link>
                                    </p>
                                )}
                                <p className="mt-1 text-sm text-gray-800 dark:text-gray-200">{comment.content}</p>
                                <div className="flex items-center justify-between mt-2">
                                    <div className="flex items-center space-x-3">
                                        <button
                                            className="text-sm text-violet-600 dark:text-violet-400 font-bold"
                                            onClick={() => setReplyingTo(comment.id)}
                                        >
                                            Ответить
                                        </button>
                                        {hasReplies && (
                                            <button
                                                className="text-sm text-violet-600 dark:text-violet-400 font-bold"
                                                onClick={() => toggleExpand(comment.id)}
                                            >
                                                {expandedComments[comment.id] ? 'Скрыть ' : 'Ответы '}
                                                <i className={`fas ${expandedComments[comment.id] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                            </button>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="relative">
                                <button
                                    onClick={() => handleContextMenuClick(comment.id)}
                                    className="ml-2 text-gray-400 hover:text-violet-500 focus:outline-none transition duration-300"
                                >
                                    <i className="fas fa-ellipsis-h text-[20px]"></i>
                                </button>
                                <div className="ml-auto mt-6 flex items-center space-x-1 align-middle">
                                    {comment.likes?.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? (
                                        <button onClick={() => handleUnlikeComment(comment.id)}
                                                className="focus:outline-none">
                                            <i className="fas fa-heart text-red-500 text-[18px]"></i>
                                        </button>
                                    ) : (
                                        <button onClick={() => handleLikeComment(comment.id)}
                                                className="focus:outline-none">
                                            <i className="far fa-heart text-neutral-500 text-[18px]"></i>
                                        </button>
                                    )}
                                    <span
                                        className="text-base text-gray-600 dark:text-gray-300">{comment.likes?.length || 0}</span>
                                </div>
                                {contextMenu.commentId === comment.id && (
                                    <div ref={contextMenuRef}
                                         className="absolute right-0 mt-1 w-36 bg-white dark:bg-neutral-800 border border-gray-200 dark:border-neutral-700 rounded-lg shadow-lg">
                                        <ul>
                                            <li
                                                onClick={() => handleDeleteClick(comment.id)}
                                                className="block px-3 py-2 text-[14px] text-red-600 cursor-pointer hover:bg-gray-100 dark:hover:bg-neutral-700"
                                            >
                                                <i className="fas fa-trash-alt mr-1"></i> Удалить
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        {expandedComments[comment.id] && renderComments(comments, comment.id, level + 1)}
                    </motion.div>
                );
            });
    };


    return (
        <div className={`bg-white p-2 rounded-lg shadow-md dark:bg-neutral-800 dark:text-neutral-100`}>
            {post.user ? (
                <>
                    <div className="flex items-center mb-4">
                        {post.group ? (
                            <>
                                <img
                                    src={post.group.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${post.group.avatar}` : defaultAvatar}
                                    alt="Group Avatar"
                                    className="w-10 h-10 rounded-full mr-3 object-cover border border-neutral-300 dark:border-neutral-700"
                                />
                                <div>
                                    <p className="font-medium text-neutral-700 dark:text-neutral-300">{post.group.name}</p>
                                    <div className="flex items-center space-x-2">
                                        <p className="text-[clamp(12px,1.5vw,15px)] text-neutral-500 dark:text-neutral-400">
                                            {formatRelative(new Date(post.created_at), new Date(), {locale: ru})}
                                        </p>
                                        <i className="fas fa-circle text-[6px] text-neutral-500 dark:text-neutral-400"></i>
                                        <p className="text-[clamp(12px,1.5vw,15px)] text-neutral-500 dark:text-neutral-400">
                                            От: {post.user.name}
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <img
                                    src={post.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${post.user.avatar}` : defaultAvatar}
                                    alt="User Avatar"
                                    className="w-10 h-10 rounded-full mr-3 object-cover border border-neutral-300 dark:border-neutral-700"
                                />
                                <div>
                                    <p className="font-medium">{post.user.name}</p>
                                    <p className="text-[clamp(12px,1.5vw,15px)] text-neutral-500 dark:text-neutral-400">
                                        {formatRelative(new Date(post.created_at), new Date(), {locale: ru})}
                                    </p>
                                </div>
                            </>
                        )}
                        <i
                            className="fas fa-ellipsis-h ml-auto cursor-pointer text-neutral-500 hover:text-neutral-700 dark:text-neutral-300 dark:hover:text-neutral-100 p-2 text-2xl"
                            onClick={(e) => handleContextMenu(e, post.id)}
                            onTouchStart={(e) => handleLongPress(e, post.id)}
                        ></i>
                        {contextMenu.postId === post.id && width < 768 && (
                            <>
                                <motion.div
                                    className="fixed inset-0 bg-black bg-opacity-40 z-40"
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                    variants={overlayVariants}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => {
                                        setIsClosing(true);
                                        setY(300);
                                        setTimeout(hideContextMenu, 300);
                                    }}
                                ></motion.div>

                                <motion.div
                                    ref={contextMenuRef}
                                    className="fixed bottom-0 left-0 w-full bg-white shadow-2xl rounded-t-3xl dark:bg-neutral-800 z-50"
                                    style={{ y }} // Плавное движение по `y`
                                    initial="closed"
                                    animate={isClosing ? "closed" : "open"}
                                    exit="closed"
                                    variants={mobileMenuVariants}
                                    transition={{ type: 'spring', stiffness: 150, damping: 20 }}
                                    onTouchStart={handleTouchStart}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={handleTouchEnd}
                                >
                                    <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mt-4"></div>
                                    <button onClick={onClose}
                                            className="absolute top-3 right-5 bg-neutral-500 w-6 h-6 rounded-full flex items-center justify-center">
                                        <i className="fas fa-times text-neutral-200"></i>
                                    </button>
                                    <ul className="py-5 space-y-2">
                                        <li
                                            className="flex items-center px-5 py-3 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-lg cursor-pointer transition-all duration-150"
                                            onClick={handleSharePost}>
                                            <i className="fas fa-share mr-3 text-gray-600 dark:text-gray-300"></i>
                                            <span>Поделиться</span>
                                        </li>
                                        <li
                                            className="flex items-center px-5 py-3 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-lg cursor-pointer transition-all duration-150">
                                            <i className="fas fa-flag mr-3 text-gray-600 dark:text-gray-300"></i>
                                            <span>Пожаловаться</span>
                                        </li>
                                        <li
                                            className="flex items-center px-5 py-3 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-lg cursor-pointer transition-all duration-150"
                                            onClick={handleEditPost}>
                                            <i className="fas fa-edit mr-3 text-gray-600 dark:text-gray-300"></i>
                                            <span>Редактировать</span>
                                        </li>
                                        <li
                                            className="flex items-center px-5 py-3 hover:bg-red-100 dark:hover:bg-red-700 rounded-lg cursor-pointer transition-all duration-150"
                                            onClick={handleDeletePost}>
                                            <i className="fas fa-trash mr-3 text-red-500"></i>
                                            <span className="text-red-500">Удалить</span>
                                        </li>
                                    </ul>
                                </motion.div>
                            </>
                        )}

                        {contextMenu.postId === post.id && width >= 768 && (
                            <motion.div
                                ref={contextMenuRef}
                                className="absolute z-20 bg-neutral-50 dark:bg-neutral-800 w-56 rounded-lg shadow-md"
                                style={{top: `${contextMenu.y}px`, left: `${contextMenu.x}px`}}
                                initial={{opacity: 0, scale: 0.95}}
                                animate={{opacity: 1, scale: 1}}
                                exit={{opacity: 0, scale: 0.95}}
                                transition={{duration: 0.25}}
                            >
                                <ul className="py-1">
                                    <li
                                        className="flex items-center px-5 py-3 hover:bg-neutral-200 dark:hover:bg-neutral-700 rounded-md cursor-pointer transition-all duration-150"
                                        onClick={() => handleSharePost()}>
                                        <i className="fas fa-share mr-3 text-gray-600 dark:text-gray-300"></i>
                                        <span>Поделиться</span>
                                    </li>
                                    <li className="flex items-center px-5 py-3 hover:bg-neutral-200 dark:hover:bg-neutral-700 rounded-md cursor-pointer transition-all duration-150">
                                        <i className="fas fa-flag mr-3 text-gray-600 dark:text-gray-300"></i>
                                        <span>Пожаловаться</span>
                                    </li>
                                    <li
                                        className="flex items-center px-5 py-3 hover:bg-neutral-200 dark:hover:bg-neutral-700 rounded-md cursor-pointer transition-all duration-150"
                                        onClick={() => handleEditPost()}>
                                        <i className="fas fa-edit mr-3 text-gray-600 dark:text-gray-300"></i>
                                        <span>Редактировать</span>
                                    </li>
                                    <li
                                        className="flex items-center px-5 py-3 hover:bg-red-100 dark:hover:bg-red-600/10 rounded-md cursor-pointer transition-all duration-150"
                                        onClick={() => handleDeletePost()}>
                                        <i className="fas fa-trash mr-3 text-red-500"></i>
                                        <span className="text-red-500">Удалить</span>
                                    </li>
                                </ul>
                            </motion.div>
                        )}

                    </div>
                    {postState.content ? (
                        <div className="w-full">
                            <p className="mb-3 text-neutral-800 dark:text-neutral-200 whitespace-pre-wrap">
                                {isExpanded || postState.content.length <= characterLimit
                                    ? postState.content
                                    : (
                                        <>
                                            {postState.content.substring(0, characterLimit)}...
                                            <button
                                                onClick={toggleExpanded}
                                                className="text-violet-500 dark:text-violet-400 font-medium hover:text-violet-600 dark:hover:text-violet-500 transition-all duration-300 ml-1 inline"
                                            >
                                                Показать еще
                                            </button>
                                        </>
                                    )}
                            </p>
                        </div>
                    ) : null}

                    {width <= 768 && post.images.length > 0 && (
                        <Swiper
                            pagination={{ clickable: true }}
                            modules={[Pagination]}
                            className="mySwiper rounded-lg mb-4"
                        >
                            {post.images.map((image, index) => (
                                <SwiperSlide key={index} onClick={() => handleImageClick(index)}>
                                    <div className="relative w-full h-52">
                                        {loadingImages[index] && (
                                            <div className="absolute inset-0 flex items-center justify-center bg-gray-200 dark:bg-neutral-700 rounded-lg">
                                                <ClipLoader color="#6B46C1" loading={loadingImages[index]} size={30} />
                                            </div>
                                        )}
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/storage/${image.image_path}`}
                                            alt={`Slide ${index}`}
                                            className={`w-full h-52 object-cover rounded-lg cursor-pointer transition-opacity duration-300 ${
                                                loadingImages[index] ? 'opacity-0' : 'opacity-100'
                                            }`}
                                            onLoad={() => handleImageLoad(index)} // Убираем индикатор, когда изображение загружено
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}


                    {post.video_path && (
                        <CustomVideoPlayer videoUrl={`${process.env.REACT_APP_BASE_URL}/storage/${post.video_path}`} />
                    )}

                    {post.images.length > 0 && width > 768 && (
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mb-4">
                            {post.images.map((image, index) => (
                                <div key={image.id} className="relative w-full h-36">
                                    {loadingImages[index] && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 dark:bg-neutral-700 rounded-lg">
                                            <ClipLoader color="#6B46C1" loading={loadingImages[index]} size={30} />
                                        </div>
                                    )}
                                    <img
                                        src={`${process.env.REACT_APP_BASE_URL}/storage/${image.image_path}`}
                                        alt="Post"
                                        className={`w-full h-full object-cover rounded-lg cursor-pointer transition-opacity duration-300 ${
                                            loadingImages[index] ? 'opacity-0' : 'opacity-100'
                                        }`}
                                        onLoad={() => handleImageLoad(index)}
                                        onClick={() => handleImageClick(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="flex items-center">
                        <div className="flex space-x-2">
                            <div
                                className="flex items-center bg-white shadow-md border border-neutral-200 rounded-full px-3 py-1.5 transition duration-300 hover:shadow-lg dark:bg-neutral-800 dark:border-neutral-700">
                                {likes.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? (
                                    <button onClick={handleUnlike} className="text-red-500 hover:text-red-600">
                                        <i className="fas fa-heart text-xl"></i>
                                    </button>
                                ) : (
                                    <button onClick={handleLike}
                                            className="text-neutral-500 dark:text-neutral-400 hover:text-red-500 dark:hover:text-red-400">
                                        <i className="far fa-heart text-xl"></i>
                                    </button>
                                )}
                                <span className="text-sm font-medium ml-2">{likes.length}</span>
                            </div>
                            <div
                                className="flex items-center bg-white shadow-md border border-neutral-200 rounded-full px-3 py-1.5 transition duration-300 hover:shadow-lg dark:bg-neutral-800 dark:border-neutral-700">
                                <button onClick={toggleComments}
                                        className="text-neutral-500 dark:text-neutral-400 hover:text-violet-500 dark:hover:text-violet-400">
                                    <i className="far fa-comment text-xl"></i>
                                </button>
                                <span className="text-sm font-medium ml-2">{comments.length}</span>
                            </div>
                            <div
                                className="flex items-center bg-white shadow-md border border-neutral-200 rounded-full px-4 py-1.5 transition duration-300 hover:shadow-lg dark:bg-neutral-800 dark:border-neutral-700">
                                <button
                                    className="text-neutral-500 dark:text-neutral-400 hover:text-violet-500 dark:hover:text-violet-400"
                                    onClick={handleSharePost}>
                                    <i className="fas fa-share text-xl"></i>
                                </button>
                            </div>
                        </div>

                        <div className="flex items-center text-neutral-500 dark:text-neutral-400 ml-auto">
                            <i className="mr-2 fas fa-eye text-sm"></i>
                            <span className="text-sm font-medium">
                                {formatViews(post.views)}
                            </span>
                        </div>
                    </div>

                    {showComments && (
                        <div className="space-y-6 mt-6 px-1 py-5 bg-white dark:bg-neutral-900 rounded-lg transition duration-300 md:px-2">

                            <h3 className="text-xl md:text-2xl font-semibold text-neutral-800 dark:text-neutral-200">Комментарии</h3>

                            <div className="space-y-4">
                                {renderComments(comments)}
                            </div>

                            {/* Модальное окно подтверждения удаления */}
                            {showDeleteCommentConfirm && (
                                <div
                                    className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 backdrop-blur-sm">
                                    <motion.div
                                        className="bg-white dark:bg-neutral-800 p-6 rounded-lg shadow-lg transform w-[90%] max-w-md"
                                        variants={modalVariants}
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        transition={{type: 'spring', stiffness: 300, damping: 20}}
                                    >
                                        <p className="text-lg text-neutral-800 dark:text-neutral-200 text-center">
                                            Вы уверены, что хотите удалить этот комментарий?
                                        </p>
                                        <div className="flex justify-center space-x-4 mt-6">
                                            <button
                                                onClick={() => setShowDeleteCommentConfirm(false)}
                                                className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition-colors"
                                            >
                                                Отмена
                                            </button>
                                            <button
                                                onClick={handleConfirmDelete}
                                                className="w-full px-4 py-2 text-red-500 border border-transparent hover:bg-red-100 dark:hover:bg-red-600/10 rounded-lg transition-all duration-300 ease-in-out"
                                            >
                                                Удалить
                                            </button>
                                        </div>
                                    </motion.div>
                                </div>
                            )}

                            {/* Форма добавления комментария */}
                            <form
                                onSubmit={handleCommentSubmit}
                                className="flex items-center space-x-2 md:space-x-3 bg-gray-100 dark:bg-neutral-800 p-2 rounded-full shadow-inner transition duration-300 w-full">

                                <input
                                    type="text"
                                    placeholder={replyingTo ? "Напишите ответ..." : "Напишите комментарий..."}
                                    value={commentContent}
                                    onChange={(e) => setCommentContent(e.target.value)}
                                    className="flex-grow px-3 py-2 text-sm md:text-base border-none rounded-full text-neutral-800 dark:text-neutral-200 bg-transparent placeholder-neutral-500 dark:placeholder-neutral-600 focus:outline-none focus:ring-0"
                                />

                                {/* Кнопка отмены ответа */}
                                {replyingTo && (
                                    <button
                                        type="button"
                                        onClick={handleCancelReply}
                                        className="text-neutral-500 dark:text-neutral-400 hover:text-violet-500 dark:hover:text-violet-400">
                                        <i className="fas fa-times text-lg md:text-xl"></i>
                                    </button>
                                )}
                                <button
                                    type="submit"
                                    className="flex items-center justify-center w-9 h-9 md:w-10 md:h-10 bg-violet-600 text-white rounded-full shadow-lg hover:shadow-xl hover:bg-violet-700 transition duration-300">
                                    <i className="fas fa-paper-plane text-sm md:text-base"></i>
                                </button>
                            </form>
                        </div>
                    )}

                    {selectedImageIndex !== null && (
                        <Modal
                            images={post.images}
                            selectedIndex={selectedImageIndex}
                            onClose={closeModal}
                            nextImage={nextImage}
                            prevImage={prevImage}
                            post={post}
                            handleUnlike={handleUnlike}
                            handleLike={handleLike}
                            handleAddComment={handleAddComment}
                            onShare={handleSharePost}
                        />
                    )}
                    {showEditModal && (
                        <EditPostModal
                            post={postState}
                            onClose={() => setShowEditModal(false)}
                            onSave={handleSavePost}
                        />
                    )}
                    {showDeleteConfirm && (
                        <DeletePost
                            show={showDeleteConfirm}
                            postId={post.id}  // Pass postId explicitly
                            onClose={() => setShowDeleteConfirm(false)}
                            onDelete={() => deletePost(post.id)}  // Pass postId here to deletePost
                        />
                    )}

                    {showShareModal && (
                        <SharePostModal
                            post={post}
                            onClose={() => setShowShareModal(false)}
                            onSend={sendPostToUser}
                        />
                    )}
                </>
            ) : (
                <ClipLoader color="#36d7b7" size={50}/>
            )}
        </div>
    );
}

export default Post;
