import React from 'react';
import { Link } from 'react-router-dom';

export default function BackLink() {
    const handleBackClick = (event) => {
        event.preventDefault();
        if (window.history.length > 1) {
            window.history.back();
        } else {
            window.location.href = '/';
        }
    };

    return (
        <Link
            to="#"
            onClick={handleBackClick}
            className="cursor-pointer text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400"
        >
            <i className="fas fa-chevron-left mr-2"></i>Назад
        </Link>
    );
}
