import React, { useState, useEffect } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

const UserStatus = ({ userId, initialStatus, initialLastSeen }) => {
    const [isOnline, setIsOnline] = useState(initialStatus);
    const [lastSeen, setLastSeen] = useState(initialLastSeen);

    useEffect(() => {
        // Configure Echo instance with Pusher as the broadcaster
        const echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_APP_KEY,
            cluster: 'eu',
            encrypted: true,
            forceTLS: true,
        });

        // Subscribe to the channel and listen for the event
        echo.channel('users')
            .listen('UserOnlineStatus', (data) => {
                if (data.user_id === userId) {
                    setIsOnline(data.is_online);
                    setLastSeen(data.last_activity);
                }
            });

        // Clean up the subscription when component unmounts
        return () => {
            echo.leaveChannel('users');
        };
    }, [userId]);

    // Helper function to format 'last seen' date
    const formatLastSeen = (datetime) => {
        if (!datetime) return '';
        const date = new Date(datetime);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <div className="flex items-center text-gray-500 dark:text-white text-sm">
            <i className={`fas fa-circle ${isOnline ? 'text-green-500' : 'text-red-500'} mr-2 text-[10px] w-2 h-2`} />
            {isOnline ? 'онлайн' : `последний раз в сети ${formatLastSeen(lastSeen)}`}
        </div>
    );
};

export default UserStatus;
