import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';
import useWindowSize from '../hooks/useWindowSize';
import BackLink from "./BackLink";

const GroupList = ({ groupId }) => {
    const [userGroups, setUserGroups] = useState([]);
    const [subscribedGroups, setSubscribedGroups] = useState([]);
    const [otherGroups, setOtherGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewMode, setViewMode] = useState('grid');
    const [sortOption, setSortOption] = useState('name');
    const [showActiveOnly, setShowActiveOnly] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);

    const { width } = useWindowSize();

    const fetchCategorizedGroups = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}/categorized`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setUserGroups(response.data.user_groups);
            setSubscribedGroups(response.data.subscribed_groups);
            setOtherGroups(response.data.other_groups);
        } catch (error) {
            console.error('Error fetching categorized groups:', error);
            toast.error('Failed to load groups.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategorizedGroups();
    }, []);

    const filterAndSortGroups = (groups) => {
        return groups
            .filter(group => group.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .filter(group => !showActiveOnly || new Date(group.updated_at) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
            .sort((a, b) => {
                if (sortOption === 'name') return a.name.localeCompare(b.name);
                return new Date(b.created_at) - new Date(a.created_at);
            });
    };

    return (
        <motion.div
            className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-6 dark:text-neutral-100"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <Toaster position="top-right" reverseOrder={false}/>
            <BackLink/>

            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-extrabold">Группы</h1>
                {width < 768 ? (
                    <motion.button
                        className="bg-violet-500 text-white w-10 h-10 rounded-lg"
                        onClick={() => setFiltersVisible(!filtersVisible)}
                        whileTap={{scale: 0.9}}
                    >
                        <i className="fas fa-filter text-xl"></i>
                    </motion.button>
                ) : (
                    <motion.div className="flex space-x-4">
                        <motion.input
                            type="text"
                            className="p-2 rounded-lg border border-gray-300 dark:bg-neutral-700 dark:border-neutral-600"
                            placeholder="Поиск групп..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            whileFocus={{scale: 1.05}}
                        />
                        <motion.select
                            className="p-2 rounded-lg border border-gray-300 dark:bg-neutral-700 dark:border-neutral-600"
                            value={sortOption}
                            onChange={(e) => setSortOption(e.target.value)}
                            whileFocus={{scale: 1.05}}
                        >
                            <option value="name">По имени</option>
                            <option value="date">По дате создания</option>
                        </motion.select>
                        <motion.button
                            className={`p-2 px-4 rounded-lg ${viewMode === 'grid' ? 'bg-violet-500 text-white' : 'bg-gray-200 dark:bg-neutral-700'} transition`}
                            onClick={() => setViewMode('grid')}
                            whileTap={{scale: 0.9}}
                        >
                            <i className="fas fa-th-large"></i>
                        </motion.button>
                        <motion.button
                            className={`p-2 px-4 rounded-lg ${viewMode === 'list' ? 'bg-violet-500 text-white' : 'bg-gray-200 dark:bg-neutral-700'} transition`}
                            onClick={() => setViewMode('list')}
                            whileTap={{scale: 0.9}}
                        >
                            <i className="fas fa-bars"></i>
                        </motion.button>
                        <motion.div className="flex items-center bg-gray-200 dark:bg-neutral-700 rounded-lg p-2">
                            <motion.input
                                type="checkbox"
                                id="active-groups"
                                checked={showActiveOnly}
                                onChange={() => setShowActiveOnly(!showActiveOnly)}
                                className="mr-2 w-4 h-4 rounded-lg border-gray-300 dark:bg-neutral-700 dark:border-neutral-600"
                                whileTap={{scale: 0.9}}
                            />
                            <motion.label htmlFor="active-groups">Только активные группы</motion.label>
                        </motion.div>
                    </motion.div>
                )}
            </div>

            <motion.h2 className="text-lg font-bold mb-4">
                <Link
                    to="/create-group"
                    className="inline-flex items-center bg-violet-500 hover:bg-violet-700 text-white font-semibold py-2 px-4 rounded-lg shadow transition duration-300"
                    whileHover={{scale: 1.05}}
                >
                    <i className="fas fa-plus mr-2"></i> Создать свою группу
                </Link>
            </motion.h2>


            {width < 768 && filtersVisible && (
                <motion.div
                    initial={{height: 0, opacity: 0}}
                    animate={{height: 'auto', opacity: 1}}
                    exit={{height: 0, opacity: 0}}
                    className="mb-6 flex flex-col space-y-4 bg-gray-100 dark:bg-neutral-800 p-4 rounded-lg shadow-lg"
                >
                    <motion.input
                        type="text"
                        className="p-2 rounded-lg border border-gray-300 dark:bg-neutral-700 dark:border-neutral-600"
                        placeholder="Поиск групп..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <motion.select
                        className="p-2 rounded-lg border border-gray-300 dark:bg-neutral-700 dark:border-neutral-600"
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                    >
                        <option value="name">По имени</option>
                        <option value="date">По дате создания</option>
                    </motion.select>
                    <motion.div className="flex items-center bg-gray-200 dark:bg-neutral-700 rounded-lg p-2">
                        <motion.input
                            type="checkbox"
                            id="active-groups-mobile"
                            checked={showActiveOnly}
                            onChange={() => setShowActiveOnly(!showActiveOnly)}
                            className="mr-2 w-4 h-4 rounded-lg border-gray-300 dark:bg-neutral-700 dark:border-neutral-600"
                        />
                        <motion.label htmlFor="active-groups-mobile">Только активные группы</motion.label>
                    </motion.div>
                </motion.div>
            )}

            {loading ? (
                <div className="flex justify-center items-center min-h-[300px]">
                    <ClipLoader color="#6B46C1" size={50}/>
                </div>
            ) : (
                <>
                    <GroupCategory title="Мои группы" groups={filterAndSortGroups(userGroups)} viewMode={viewMode}/>
                    <GroupCategory title="Подписки" groups={filterAndSortGroups(subscribedGroups)} viewMode={viewMode}/>
                    <GroupCategory title="Рекомендованные группы" groups={filterAndSortGroups(otherGroups)}
                                   viewMode={viewMode}/>
                </>
            )}
        </motion.div>
    );
};

const GroupCategory = ({title, groups, viewMode}) => (
    <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>
        <AnimatePresence mode="wait">
            <motion.div
                key={viewMode}
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -20}}
                transition={{duration: 0.3}}
                className={viewMode === 'grid' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6' : 'space-y-4'}
            >
                {groups.map(group => (
                    <GroupCard key={group.id} group={group} />
                ))}
            </motion.div>
        </AnimatePresence>
    </div>
);

const GroupCard = ({ group }) => {
    const isActive = new Date(group.updated_at) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

    return (
        <motion.div
            className="bg-white rounded-lg shadow-lg dark:bg-neutral-800 p-4"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            <Link to={`/group/${group.id}`} className="flex items-center space-x-4">
                <img
                    src={group.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${group.avatar}` : 'https://via.placeholder.com/1200x600.png?text=Group+Avatar'}
                    alt={group.name}
                    className="w-14 h-14 rounded-full"
                />
                <div>
                    <h2 className="text-xl font-bold">{group.name}</h2>
                    <p className="text-gray-700 dark:text-white">{group.description}</p>
                    <p className="text-sm text-gray-500">Создана: {new Date(group.created_at).toLocaleDateString()}</p>
                    <p className="text-sm text-gray-500">Участников: {group.members_count}</p>
                    {isActive && <p className="text-sm text-green-500">Активная</p>}
                </div>
            </Link>
        </motion.div>
    );
};

export default GroupList;
