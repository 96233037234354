import React, { useEffect, useState } from "react";
import BackLink from "../BackLink";
import { ClipLoader } from "react-spinners";

export default function CurrencyDetails() {
    const [exchangeRates, setExchangeRates] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [amount, setAmount] = useState(1);
    const [selectedCurrency, setSelectedCurrency] = useState("USD");
    const [showAllRates, setShowAllRates] = useState(false);

    useEffect(() => {
        fetchExchangeRates();
    }, []);

    const fetchExchangeRates = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch("https://api.exchangerate-api.com/v4/latest/RUB");
            if (!response.ok) throw new Error("Не удалось загрузить курсы валют");
            const data = await response.json();
            const rates = {
                USD: (1 / data.rates.USD).toFixed(2),
                EUR: (1 / data.rates.EUR).toFixed(2),
                ...Object.entries(data.rates).reduce((acc, [currency, rate]) => {
                    acc[currency] = (1 / rate).toFixed(2);
                    return acc;
                }, {})
            };
            setExchangeRates(rates);
        } catch (err) {
            setError(err.message);
            setExchangeRates(null);
        } finally {
            setLoading(false);
        }
    };

    const filteredRates = () => {
        if (!exchangeRates) return {};
        if (!searchQuery) return exchangeRates;
        return Object.fromEntries(
            Object.entries(exchangeRates).filter(([currency]) =>
                currency.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleCurrencyChange = (e) => {
        setSelectedCurrency(e.target.value);
    };

    const toggleShowAllRates = () => {
        setShowAllRates(!showAllRates);
    };

    const convertedAmount = exchangeRates && exchangeRates[selectedCurrency]
        ? (amount * exchangeRates[selectedCurrency]).toFixed(2)
        : 0;

    return (
        <div className="container mx-auto p-4 min-h-screen bg-gray-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-100 transition-colors duration-300">
            <BackLink />
            <h1 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-6">Курсы валют</h1>

            <div className="mb-8">
                <h2 className="text-2xl font-bold text-purple-600 dark:text-purple-400 mb-4">Конвертер валют</h2>
                <div className="flex items-center space-x-4">
                    <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        className="w-1/4 px-4 py-2 rounded-lg bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100 focus:outline-none border border-neutral-300 dark:border-neutral-700"
                        placeholder="Сумма"
                    />
                    <select
                        value={selectedCurrency}
                        onChange={handleCurrencyChange}
                        className="w-1/4 px-4 py-2 rounded-lg bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100 focus:outline-none border border-neutral-300 dark:border-neutral-700"
                    >
                        {exchangeRates && Object.keys(exchangeRates).map((currency) => (
                            <option key={currency} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </select>
                    <p className="text-lg text-neutral-700 dark:text-neutral-300">
                        = {convertedAmount} RUB
                    </p>
                </div>
            </div>

            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Поиск по валюте (например, USD)"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="w-full px-4 py-2 rounded-lg bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100 focus:outline-none border border-neutral-300 dark:border-neutral-700"
                />
            </div>
            <button
                onClick={toggleShowAllRates}
                className="px-4 py-2 mb-4 bg-purple-600 hover:bg-purple-700 dark:bg-purple-500 dark:hover:bg-purple-600 text-white font-semibold rounded-lg transition-all duration-200"
            >
                {showAllRates ? "Свернуть список" : "Показать все валюты"}
            </button>

            {/* Currency Rates List */}
            {loading ? (
                <div className="text-center mt-6">
                    <ClipLoader color="#36d7b7" size={50} />
                </div>
            ) : error ? (
                <p className="text-center text-xl text-red-500">{error}</p>
            ) : (
                <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {Object.entries(filteredRates()).slice(0, showAllRates ? undefined : 2).map(([currency, rate]) => (
                        <div
                            key={currency}
                            className="bg-white dark:bg-neutral-800 rounded-lg shadow-lg p-4 text-center transition-transform transform duration-300"
                        >
                            <h2 className="text-2xl font-semibold text-purple-600 dark:text-purple-400">{currency}</h2>
                            <p className="text-xl font-medium text-neutral-700 dark:text-neutral-300">1 {currency} = {rate} RUB</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
