import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Home from './components/Home';
import Header from './components/Header';
import Profile from './components/Profile';
import { AuthProvider } from './context/AuthContext';
import { Toaster } from 'react-hot-toast';
import ChatList from './components/ChatList';
import Chat from './components/Chat';
import AddFriend from './components/friends/AddFriend';
import CreateChat from './components/CreateChat';
import FriendList from './components/friends/FriendList';
import Settings from './components/Settings';
import ChangeData from './components/settings/ChangeData';
import DeleteAccount from './components/settings/DeleteAccount';
import Report from './components/settings/Report';
import Support from './components/settings/Support';
import UserProfile from './components/UserProfile';
import Post from "./components/Post";
import PostList from "./components/PostList";
import CreatePost from "./components/CreatePost";
import Search from "./components/Search";
import PostList2 from "./components/PostList2";
import Notifications from "./components/Notifications";
import CreateGroup from "./components/CreateGroup";
import GroupList from "./components/GroupList";
import GroupDetails from "./components/GroupDetails";
import Echo from 'laravel-echo';
import Pusher from "pusher-js";
import MobileMenu from "./components/MobileMenu";
import Services from "./components/services/Services";
import WeatherDetails from "./components/services/WetherDetails";
import CurrencyDetails from "./components/services/CurrencyDetails";

function App() {
  const [posts, setPosts] = useState([]);

  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: true
    });
  }

  return (
    <AuthProvider>
    <Router>
      <div className='h-min-screen h-max-screen dark:bg-neutral-900'>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/chats" element={<ChatList />} />
          <Route path="/chats/:chatId" element={<Chat />} />
          <Route path="/add-friend" element={<AddFriend />} />
          <Route path="/create-chat" element={<CreateChat />} />
          <Route path='/friends' element={<FriendList />} />
          <Route path='messages' element={<ChatList />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/settings/change-password' element={<ChangeData />} />
          <Route path='/settings/delete-account' element={<DeleteAccount />} />
          <Route path='/settings/report-issue' element={<Report />} />
          <Route path='/settings/contact-support' element={<Support />} />
          <Route path='/profile/:userId' element={<UserProfile />} />
          <Route path='/posts' element={<PostList setPosts={setPosts} posts={posts} />} />
          <Route path='/posts/all' element={<PostList2 setPosts={setPosts} posts={posts} />} />
          <Route path='/posts/:postId' element={<Post />} />
          <Route path='/create-post' element={<CreatePost />} />
          <Route path="/groups" element={<GroupList />} />
          <Route path="/create-group" element={<CreateGroup />} />
          <Route path="/group/:id" element={<GroupDetails />} />
          <Route path='/search' element={<Search />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/menu' element={<MobileMenu />} />
          <Route path='/services' element={<Services />} />
          <Route path='/services/weather' element={<WeatherDetails />} />
          <Route path='/services/currency' element={<CurrencyDetails />} />
        </Routes>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </Router>
    </AuthProvider>
  );
}

export default App;
