import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';


const CreateGroup = () => {
    const [groupData, setGroupData] = useState({
        name: '',
        description: '',
        privacy: 'public',
        avatar: null,
        cover: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
    const ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        // Проверка файлов (аватар и обложка)
        if (files) {
            const file = files[0];
            if (file.size > MAX_FILE_SIZE) {
                setError(`${name === 'avatar' ? 'Аватар' : 'Обложка'} превышает допустимый размер 2MB.`);
                toast.error(`${name === 'avatar' ? 'Аватар' : 'Обложка'} превышает допустимый размер 2MB.`);
                return;
            }
            if (!ALLOWED_FORMATS.includes(file.type)) {
                setError(`${name === 'avatar' ? 'Аватар' : 'Обложка'} имеет недопустимый формат.`);
                toast.error(`${name === 'avatar' ? 'Аватар' : 'Обложка'} имеет недопустимый формат.`);
                return;
            }
        }

        setGroupData((prevData) => ({
            ...prevData,
            [name]: files ? files[0] : value,
        }));
        setError(null); // Сброс ошибки при корректном вводе
    };

    const validateForm = () => {
        const { name, description } = groupData;
        if (!name || !description) {
            setError('Название и описание обязательны');
            toast.error('Название и описание обязательны.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formData = new FormData();
        Object.keys(groupData).forEach((key) => {
            formData.append(key, groupData[key]);
        });

        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/groups`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            toast.success('Группа успешно создана!');
            navigate('/groups');
        } catch (error) {
            setError('Ошибка при создании группы. Попробуйте снова.');
            toast.error('Ошибка при создании группы.');
            console.error('Error creating group:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <motion.form
            onSubmit={handleSubmit}
            className="container mx-auto rounded-lg mt-6 p-6 bg-white shadow-lg dark:bg-neutral-800 dark:text-neutral-100"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Link
                to="/groups"
                className="flex items-center text-gray-700 dark:text-white hover:text-violet-500 dark:hover:text-violet-400 transition duration-200 mb-6"
            >
                <i className="fas fa-chevron-left mr-2"></i>Назад
            </Link>

            <h1 className="text-3xl font-extrabold mb-6">Создать группу</h1>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <div className="mb-4">
                <label className="block text-gray-700 dark:text-white">Название</label>
                <motion.input
                    type="text"
                    name="name"
                    value={groupData.name}
                    onChange={handleChange}
                    className="w-full rounded-lg p-2 bg-gray-100 dark:bg-neutral-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-500 transition duration-300"
                    required
                    placeholder="Название группы"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 dark:text-white">Описание</label>
                <motion.textarea
                    name="description"
                    value={groupData.description}
                    onChange={handleChange}
                    className="w-full rounded-lg p-2 bg-gray-100 dark:bg-neutral-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-500 transition duration-300"
                    required
                    rows="4"
                    placeholder="Описание группы"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 dark:text-white">Аватар</label>
                <motion.input
                    type="file"
                    name="avatar"
                    onChange={handleChange}
                    className="w-full rounded-lg p-2 bg-gray-100 dark:bg-neutral-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-500 transition duration-300 file:border-none file:rounded-md file:bg-violet-100 file:text-violet-700 file:transition-colors file:duration-300 file:ease-in-out file:hover:bg-violet-200 dark:file:bg-neutral-600 dark:file:text-white dark:file:hover:bg-neutral-700"
                    accept="image/*"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 dark:text-white">Обложка</label>
                <motion.input
                    type="file"
                    name="cover"
                    onChange={handleChange}
                    className="w-full rounded-lg p-2 bg-gray-100 dark:bg-neutral-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-500 transition duration-300 file:border-none file:rounded-md file:bg-violet-100 file:text-violet-700 file:transition-colors file:duration-300 file:ease-in-out file:hover:bg-violet-200 dark:file:bg-neutral-600 dark:file:text-white dark:file:hover:bg-neutral-700"
                    accept="image/*"
                />
            </div>

            <div className="mb-6">
                <label className="block text-gray-700 dark:text-white">Приватность</label>
                <motion.select
                    name="privacy"
                    value={groupData.privacy}
                    onChange={handleChange}
                    className="w-full rounded-lg p-2 bg-gray-100 dark:bg-neutral-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-500 transition duration-300"
                    required
                >
                    <option value="public">Публичная</option>
                    <option value="private">Приватная</option>
                </motion.select>
            </div>

            <motion.button
                type="submit"
                className="bg-violet-500 hover:bg-violet-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg focus:outline-none focus:ring-4 focus:ring-violet-300 transition duration-300"
                disabled={isLoading}
            >
                {isLoading ? 'Создание...' : 'Создать'}
            </motion.button>
        </motion.form>
    );
};

export default CreateGroup;
