import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import PostModal from './PostModal';
import CreatePost from './CreatePost';
import useWindowSize from '../hooks/useWindowSize';
import GroupPostList from "./GroupPostList";
import {ClipLoader} from "react-spinners";

const GroupDetails = () => {
    const { id: groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMember, setIsMember] = useState(false);
    const [isCreator, setIsCreator] = useState(false);
    const [members, setMembers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editFormData, setEditFormData] = useState({
        name: '',
        description: '',
        privacy: 'public',
        avatar: null,
        cover: null,
    });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [posts, setPosts] = useState([]);
    const [showCreateButton, setShowCreateButton] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { width } = useWindowSize();

    useEffect(() => {
        const fetchGroupDetails = async () => {
            if (!groupId) {
                toast.error('Group ID is undefined');
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                const groupData = response.data.group;
                setGroup(groupData);
                setIsMember(response.data.is_member);
                setIsCreator(response.data.is_creator);
                setMembers(groupData.members);

                const postsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}/posts`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            setPosts(postsResponse.data);

            setEditFormData({
                name: groupData.name || '',
                description: groupData.description || '',
                privacy: groupData.privacy || 'public',
            });
        } catch (error) {
            toast.error('Ошибка при загрузке данных группы');
        } finally {
            setLoading(false);
        }
    };

    fetchGroupDetails();
}, [groupId]);


useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            setShowCreateButton(false);
        } else {
            setShowCreateButton(true);
        }
        lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

const openModal = () => {
    setIsModalOpen(true);
};

const closeModal = () => {
    setIsModalOpen(false);
};

const addPost = (newPost) => {
    setPosts([newPost, ...posts]); // Добавляем новый пост в начало списка
};

const handleEdit = () => {
    setIsEditing(true);
    setShowMenu(false);
};

const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'avatar' || name === 'cover') {
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: files[0],
        }));
    } else {
        setEditFormData({
            ...editFormData,
            [name]: value,
        });
    }
};

const handleUpdate = async (e) => {
    e.preventDefault();

    try {
        const formData = new FormData();

        if (editFormData.name) {
            formData.append('name', editFormData.name);
        }
        if (editFormData.description) {
            formData.append('description', editFormData.description);
        }
        if (editFormData.privacy) {
            formData.append('privacy', editFormData.privacy);
        }
        if (editFormData.avatar) {
            formData.append('avatar', editFormData.avatar);
        }
        if (editFormData.cover) {
            formData.append('cover', editFormData.cover);
        }

        // Логируем все данные перед отправкой
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        const response = await axios.put(`${process.env.REACT_APP_API_URL}/groups/${groupId}, formData`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data) {
            setGroup(response.data);
        }

        setIsEditing(false);
        toast.success('Группа успешно обновлена');
    } catch (error) {
        if (error.response && error.response.data.errors) {
            Object.entries(error.response.data.errors).forEach(([field, messages]) => {
                toast.error(`${field}: ${messages.join(', ')}`);
            });
        } else {
            toast.error('Ошибка при обновлении группы');
        }
    }
};

const handleDelete = async () => {
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        toast.success('Группа успешно удалена');
        window.location.href = '/groups';
    } catch (error) {
        toast.error('Ошибка удаления группы');
    }
};

const handleJoin = async () => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/groups/${groupId}/join`, {}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
    const userId = localStorage.getItem('user_id');
    setIsMember(true);
    setMembers((prevMembers) => [...prevMembers, { id: userId, name: localStorage.getItem('user_name') }]);
    toast.success('Вы присоединились к группе');
} catch (error) {
    toast.error('Ошибка присоединения к группе');
}
};

const handleLeave = async () => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/groups/${groupId}/leave`, {}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
    const userId = localStorage.getItem('user_id');
    setIsMember(false);
    setMembers((prevMembers) => prevMembers.filter((member) => member.id !== userId));
    toast.success('Вы вышли из группы');
} catch (error) {
    toast.error('Ошибка выхода из группы');
}
};

const copyGroupLink = () => {
    const groupLink = `${window.location.origin}/groups/${groupId}`;
    navigator.clipboard.writeText(groupLink);
    toast.success('Ссылка на группу скопирована в буфер обмена');
};

if (loading) return <p className="text-center"><ClipLoader color="#6B46C1" size={50} className="h-screen w-full justify-center items-center" /></p>;
if (!group) return <p className="text-center">Группа не найдена.</p>;

return (
    <div className="container mx-auto p-2 dark:text-neutral-100">
        <Toaster position="top-right" reverseOrder={false} />
        <Link to="/groups" className="flex items-center text-gray-700 dark:text-white hover:text-violet-500 dark:hover:text-violet-400 transition duration-200 mb-6">
            <i className="fas fa-chevron-left mr-2"></i>Назад
        </Link>

        <div
            className="max-w-3xl mx-auto p-6 bg-gray-50 rounded-lg shadow-lg mt-10 dark:bg-neutral-800 dark:text-neutral-100 relative">
            {isCreator && (
                <div className="absolute top-4 right-4">
                    <button
                        onClick={() => setShowMenu(!showMenu)}
                        className="text-gray-700 dark:text-neutral-200 focus:outline-none"
                    >
                        <i className="fas fa-ellipsis-v text-xl bg-neutral-300 rounded-full w-8 h-8 items-center justify-center flex dark:bg-neutral-700"></i>
                    </button>
                    {showMenu && (
                        <div
                            className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg dark:bg-neutral-700">
                            <ul className="py-1">
                                <li
                                    className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-600 cursor-pointer"
                                    onClick={copyGroupLink}
                                >
                                    <i className="fas fa-link mr-2"></i>
                                    Копировать ссылку
                                </li>
                                <li
                                    className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-600 cursor-pointer"
                                    onClick={handleEdit}
                                >
                                    <i className="fas fa-edit mr-2"></i>
                                    Редактировать группу
                                </li>
                                <li
                                    className="flex items-center text-red-500 px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-600 cursor-pointer"
                                    onClick={() => setShowDeleteModal(true)}
                                >
                                    <i className="fas fa-trash-alt mr-2"></i>
                                    Удалить группу
                                </li>

                            </ul>
                        </div>
                    )}
                </div>
            )}

            {group.cover && (
                <img
                    src={group.cover ? `${process.env.REACT_APP_BASE_URL}/storage/${group.cover}` : 'https://'}
                    alt="Group Cover"
                    className="w-full h-48 object-cover rounded-lg mb-4"
                />
            )}
            {group.avatar && (
                <img
                    src={`${process.env.REACT_APP_BASE_URL}/storage/${group.avatar}`}
                    alt="Group Avatar"
                    className="w-32 h-32 object-cover rounded-full border-4 border-white shadow-lg mb-4"
                />
            )}

            <div className="">
                <h1 className="text-4xl font-semibold text-gray-800 dark:text-neutral-100 mb-2">{group.name}</h1>
            </div>

            <p className="text-gray-500 dark:text-neutral-400 mb-4">Подписчиков: <span className="font-semibold">
                {group.members ? group.members.length : 0}</span></p>

            <p className="text-gray-600 dark:text-neutral-300 mb-4">{group.description}</p>

            {isCreator ? (
                <p className="mt-4 text-green-600">Вы являетесь создателем этой группы.</p>
            ) : (
                isMember ? (
                    <button
                        onClick={handleLeave}
                        className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                    >
                        Выйти из группы
                    </button>
                ) : (
                    <button
                        onClick={handleJoin}
                        className="mt-4 px-4 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition duration-300"
                    >
                        Присоединиться к группе
                    </button>
                )
            )}


            <h2 className="text-2xl font-semibold mt-6 mb-4">Посты группы</h2>
            <GroupPostList groupId={groupId}/>

            {width < 768 && (
                <button
                    onClick={openModal}
                    className={`fixed bottom-24 right-5 flex items-center justify-center bg-violet-500 hover:bg-violet-600 text-white font-semibold px-5 py-3.5 rounded-full shadow-lg transition-all duration-500 transform ${
                        showCreateButton ? 'opacity-100 scale-100' : 'opacity-0 scale-75'
                    } z-50`}
                >
                    <i className="fas fa-plus text-xl"></i>
                </button>
            )}

            {width > 768 && (
                <button
                    onClick={openModal}
                    className="fixed bottom-10 right-10 flex items-center justify-center bg-violet-500 hover:bg-violet-600 text-white font-semibold px-4 py-2.5 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 z-auto"
                >
                    <i className="fas fa-plus text-xl"></i>
                </button>
            )}

            {isModalOpen && (
                <PostModal closeModal={closeModal}>
                    <CreatePost addPost={addPost} closeModal={closeModal}
                                groupId={group.id}/>
                </PostModal>
            )}

            {isEditing && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg dark:bg-neutral-800 w-96">
                        <h2 className="text-2xl font-semibold mb-4">Редактировать группу</h2>
                        <form onSubmit={handleUpdate}>
                            <div>
                                <label>Название группы</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={editFormData.name}
                                    onChange={handleChange}
                                    className="w-full border rounded p-2 mb-4 dark:text-black"
                                    required
                                />
                            </div>
                            <div>
                                <label>Описание группы</label>
                                <textarea
                                    name="description"
                                    value={editFormData.description}
                                    onChange={handleChange}
                                    className="w-full border rounded p-2 mb-4 dark:text-black"
                                />
                            </div>
                            <div>
                                <label>Приватность</label>
                                <select
                                    name="privacy"
                                    value={editFormData.privacy}
                                    onChange={handleChange}
                                    className="w-full border rounded p-2 mb-4 dark:text-black"
                                >
                                    <option value="public">Публичная</option>
                                    <option value="private">Приватная</option>
                                </select>
                            </div>
                            <div>
                                <label>Аватар</label>
                                <input
                                    type="file"
                                    name="avatar"
                                    onChange={handleChange}
                                    className="w-full border rounded p-2 mb-4 dark:text-black"
                                />
                            </div>
                            <div>
                                <label>Обложка</label>
                                <input
                                    type="file"
                                    name="cover"
                                    onChange={handleChange}
                                    className="w-full border rounded p-2 mb-4 dark:text-black"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setIsEditing(false)}
                                    className="mr-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                                >
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-700"
                                >
                                    Сохранить
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg dark:bg-neutral-800">
                        <p className="mb-4">Вы уверены, что хотите удалить группу? Это действие нельзя отменить.</p>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowDeleteModal(false)}
                                className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 mr-2"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={handleDelete}
                                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                            >
                                Да, удалить
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>
);
};

export default GroupDetails;