import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import useWindowSize from "../hooks/useWindowSize";

const notifyError = () => toast.error('Произошла ошибка при изменении поста!');

function EditPostModal({ post, onClose, onSave }) {
    const { width } = useWindowSize();
    const [content, setContent] = useState(post.content);
    const [swipeStartY, setSwipeStartY] = useState(0);
    const [swipeOffsetY, setSwipeOffsetY] = useState(0);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const handleSave = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
                content: content
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (typeof onSave === 'function') {
                onSave(response.data);
            }
            onClose();
        } catch (error) {
            notifyError();
            console.error('There was an error saving the post!', error);
        }
    };

    const handleTouchStart = (e) => {
        setSwipeStartY(e.touches[0].clientY);
        setSwipeOffsetY(0);
    };

    const handleTouchMove = (e) => {
        const currentY = e.touches[0].clientY;
        const offsetY = currentY - swipeStartY;
        if (offsetY > 0) {
            setSwipeOffsetY(offsetY);
        }
    };

    const handleTouchEnd = () => {
        if (swipeOffsetY > 150) {
            onClose();
        } else {
            setSwipeOffsetY(0);
        }
    };

    const overlayVariants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    };

    const mobileModalVariants = {
        open: { y: swipeOffsetY, opacity: 1 },
        closed: { y: '100%', opacity: 0 }
    };

    const desktopModalVariants = {
        open: { scale: 1, opacity: 1, transition: { type: 'spring', stiffness: 200, damping: 25 } },
        closed: { scale: 0.9, opacity: 0, transition: { type: 'spring', stiffness: 200, damping: 25 } }
    };

    return (
        <AnimatePresence>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                transition={{ duration: 0.3 }}
                onClick={onClose}
            />

            {width < 768 ? (
                <motion.div
                    key="mobile-modal"
                    className="fixed bottom-0 left-0 w-full h-auto bg-white shadow-lg rounded-t-2xl dark:bg-neutral-800 z-50"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={mobileModalVariants}
                    style={{ y: swipeOffsetY }}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div className="relative p-5">
                        <button onClick={onClose}
                                className="absolute top-3 right-3 bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200">
                            <i className="fas fa-times text-gray-700 dark:text-gray-300"></i>
                        </button>
                        <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-5"></div>
                        <h2 className="text-xl font-semibold mb-4 text-center dark:text-white">Редактировать пост</h2>
                        <textarea
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            className="w-full border border-gray-300 p-3 rounded-lg text-lg dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                            rows="6"
                            placeholder="Введите текст..."
                        />
                        <div className="flex justify-end mt-6 space-x-3">
                            <button
                                onClick={onClose}
                                className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition duration-200"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={handleSave}
                                className="w-full px-4 py-2 text-white bg-violet-500 rounded-lg hover:bg-violet-600 dark:bg-violet-600 dark:hover:bg-violet-700 transition duration-300"
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </motion.div>
            ) : (
                <motion.div
                    key="desktop-modal"
                    className="fixed inset-0 flex items-center justify-center z-50"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={desktopModalVariants}
                >
                    <motion.div
                        className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-lg dark:bg-neutral-800"
                        variants={desktopModalVariants}
                        transition={{ duration: 0.3 }}
                    >
                        <button onClick={onClose}
                                className="absolute top-3 right-3 bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200">
                            <i className="fas fa-times text-gray-700 dark:text-gray-300"></i>
                        </button>
                        <h2 className="text-xl font-semibold mb-5 text-center dark:text-white">Редактировать пост</h2>
                        <textarea
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            className="w-full border border-gray-300 p-3 rounded-lg text-lg dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                            rows="6"
                            placeholder="Введите текст..."
                        />
                        <div className="flex justify-end mt-6 space-x-3">
                            <button
                                onClick={onClose}
                                className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition duration-200"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={handleSave}
                                className="w-full px-4 py-2  text-violet-600 rounded-lg hover:bg-violet-100 dark:text-violet-600 dark:hover:bg-violet-600/10 transition duration-300"
                            >
                                Сохранить
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default EditPostModal;
