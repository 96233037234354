import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import ProfileHeader from '../components/profile/ProfileHeader';
import ProfileAvatar from '../components/profile/ProfileAvatar';
import ProfileInfo from '../components/profile/ProfileInfo';
import ProfilePosts from '../components/profile/ProfilePosts';
import { ClipLoader } from "react-spinners";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import ConfirmLogout from "./ConfirmLogout";

function Profile() {
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const { isLoggedIn, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке профиля!', error);
      }
    };

    fetchProfile();
  }, []);

  if (!user) {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50 dark:bg-neutral-900 dark:text-white">
          <ClipLoader color="#6B46C1" size={75} />
          <p className="mt-4 text-lg">Загрузка профиля...</p>
        </div>
    );
  }

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleLogoutClick = () => {
    setShowConfirmLogout(true);
  };

  const closeConfirmLogout = () => {
    setShowConfirmLogout(false);
  };

  const confirmLogout = () => {
    handleLogout();
    closeConfirmLogout();
  };

  return (
      <>
        {width < 768 && <BottomNav />}
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <ProfileHeader isLoggedIn={isLoggedIn} handleLogout={handleLogoutClick} />
          <div className="flex flex-col lg:flex-row lg:space-x-8 mt-4">
            <aside className="lg:w-1/4 dark:bg-neutral-900 rounded-lg p-4 mb-8 lg:mb-0">
              <ProfileAvatar user={user} showModal={showModal} setShowModal={setShowModal} showCoverModal={showCoverModal} setShowCoverModal={setShowCoverModal} />
              <ProfileInfo user={user} />
            </aside>
            <main className="lg:w-3/4">
              <ProfilePosts userId={localStorage.getItem('user_id')} />
            </main>
          </div>
        </div>
        <ConfirmLogout
            isOpen={showConfirmLogout}
            onClose={closeConfirmLogout}
            onConfirm={confirmLogout}
        />
      </>
  );
}

export default Profile;
