// ConfirmLogout.jsx
import React, {useEffect} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiLogOut } from 'react-icons/fi';

function ConfirmLogout({ isOpen, onClose, onConfirm }) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 backdrop-blur-sm"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <motion.div
                    className="relative bg-white/80 dark:bg-neutral-800/80 rounded-2xl p-6 w-80 shadow-xl backdrop-blur-lg"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.4, ease: "easeInOut" }}
                >
                    <div className="flex justify-center mb-4">
                        <FiLogOut className="text-3xl text-red-500" />
                    </div>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white text-center">
                        Выход из аккаунта
                    </h3>

                    <div className="border-t border-gray-300 dark:border-neutral-700 my-4"></div>

                    <p className="text-sm text-gray-700 dark:text-gray-300 mb-6 text-center">
                        Вы уверены, что хотите выйти?
                    </p>

                    <div className="flex justify-between">
                        <button
                            onClick={onClose}
                            className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition-colors"
                        >
                            Отмена
                        </button>
                        <button
                            onClick={onConfirm}
                            className="ml-3 w-full px-4 py-2 text-red-500 border border-transparent hover:bg-red-100 dark:hover:bg-red-600/10 rounded-lg transition-all duration-300 ease-in-out"
                        >
                            Выйти
                        </button>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
}

export default ConfirmLogout;
