import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useParams, Link } from 'react-router-dom';
import UserStatus from '../components/OnlineStatus';
import BackLink from "./BackLink";
import OnlineStatus from "../components/OnlineStatus"; // Импорт нового компонента

function UserProfile() {
  const [user, setUser] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const { userId } = useParams();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUser(response.data);
      } catch (error) {
        console.error('There was an error fetching the user profile!', error);
      }
    };

    if (isLoggedIn) {
      fetchUserProfile();
    }
  }, [isLoggedIn, userId]);

  if (!user) {
    return <p className="text-center text-gray-600 dark:text-white">Загрузка профиля...</p>;
  }

  return (
      <div className="container mx-auto p-4 h-screen">
        <BackLink />

        <h2 className="text-2xl font-extrabold mb-4 mt-4 dark:text-white">Профиль пользователя</h2>
        <div className="bg-gray-100 p-4 rounded-lg shadow-md flex items-center dark:bg-neutral-700">
          <img
              src={user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}` : "https://via.placeholder.com/150"}
              alt={user.name}
              className="w-32 h-32 rounded-full mr-4 object-cover shadow-md"
          />
          <div className="ml-4">
            <p className="text-lg font-semibold mb-1 dark:text-white">{user.name}</p>
            <p className="text-gray-600 dark:text-gray-300">{user.status || 'Описание отсутствует'}</p>
            <OnlineStatus userId={user.id} initialStatus={user.is_online} initialLastSeen={user.last_activity} />
             <p className="text-gray-600 dark:text-gray-300">{user.email}</p>
          </div>
        </div>
      </div>
  );
}

export default UserProfile;
