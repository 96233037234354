import React, { useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

function CreatePost({ addPost, closeModal, groupId }) {
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [previewVideos, setPreviewVideos] = useState([]);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    const videoFiles = files.filter(file => file.type.startsWith('video/'));

    setImages(imageFiles);
    setVideos(videoFiles.length > 0 ? [videoFiles[0]] : []);

    const imagePreviewUrls = imageFiles.map(file => URL.createObjectURL(file));
    const videoPreviewUrls = videoFiles.slice(0, 1).map(file => URL.createObjectURL(file));

    setPreviewImages(imagePreviewUrls);
    setPreviewVideos(videoPreviewUrls);
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
    setPreviewImages(previewImages.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();

    if (!content && images.length === 0 && videos.length === 0) {
      setErrorMessage('Нужно добавить текст, хотя бы одно изображение или видео.');
      return;
    }

    if (content) {
      formData.append('content', content);
    }
    images.forEach(image => formData.append('images[]', image));

    if (videos.length > 0) {
      formData.append('video', videos[0]);
    }

    if (groupId) {
      formData.append('group_id', groupId);
    }

    setIsLoadingPost(true);
    setIsUploadingFiles(true);
    setErrorMessage('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      const newPost = response.data;
      addPost(newPost);
      setContent('');
      setImages([]);
      setVideos([]);
      setPreviewImages([]);
      setPreviewVideos([]);
      setUploadProgress(0);
      closeModal();
    } catch (error) {
      console.error('Ошибка при создании поста!', error);
      setErrorMessage('Произошла ошибка при создании поста. Пожалуйста, попробуйте снова.');
    } finally {
      setIsLoadingPost(false);
      setIsUploadingFiles(false);
    }
  };

  return (
      <form onSubmit={handleSubmit} className="space-y-6">
        <textarea
            value={content}
            onChange={handleContentChange}
            className="w-full border border-gray-300 rounded-lg p-3 bg-gray-100 text-gray-800 dark:bg-neutral-700 dark:border-neutral-600 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-violet-500 transition-shadow duration-300"
            placeholder="Напишите что-нибудь (необязательно)"
            rows="4"
        />

        <input
            type="file"
            multiple
            accept="image/*, video/*"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-700 dark:text-white file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 dark:file:bg-neutral-700 dark:file:text-white transition duration-300"
        />

        <div className="max-h-48 overflow-y-auto mt-3 space-y-2">
          {previewImages.length > 0 && (
              <div className="flex gap-4 mt-4 overflow-x-auto">
                {previewImages.map((url, index) => (
                    <div key={index} className="relative group">
                      <img
                          src={url}
                          alt={`Preview ${index}`}
                          className="max-w-xs max-h-32 rounded-lg shadow-lg border border-gray-200 dark:border-neutral-700 transition-transform duration-300 ease-in-out transform group-hover:scale-95"
                      />
                      <button
                          onClick={() => handleRemoveImage(index)}
                          className="absolute top-1 right-1 bg-gray-800 bg-opacity-60 text-white px-2 py-0.5 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                          title="Удалить"
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                ))}
              </div>
          )}

          {previewVideos.length > 0 && (
              <div className="grid grid-cols-1 gap-3 mt-4">
                {previewVideos.map((url, index) => (
                    <div key={index} className="relative">
                      <video controls src={url} className="w-full h-32 object-cover rounded-lg shadow-md" />
                    </div>
                ))}
              </div>
          )}
        </div>

        {isUploadingFiles && (
            <div className="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700 mt-3 shadow-lg overflow-hidden">
              <div
                  className="bg-gradient-to-r from-purple-500 to-purple-700 h-full rounded-full transition-all duration-300 ease-in-out"
                  style={{ width: `${uploadProgress}%` }}
              />
              <p className="text-sm text-gray-600 dark:text-gray-300 mt-1 text-center">
                Загрузка: {uploadProgress}%
              </p>
            </div>
        )}

        {errorMessage && <div className="text-red-500 text-center mt-2">{errorMessage}</div>}

        {isLoadingPost ? (
            <button type="button" disabled className="w-full bg-gray-400 text-white py-3 rounded-full flex justify-center">
              <ClipLoader color="white" loading={isLoadingPost} size={20} />
            </button>
        ) : (
            <button
                type="submit"
                className="w-full bg-violet-500 text-white py-3 rounded-full flex justify-center items-center transition-colors duration-300 ease-in-out hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500"
            >
              <i className="fas fa-paper-plane mr-2"></i> Опубликовать
            </button>
        )}
      </form>
  );
}

export default CreatePost;
