import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import profilePosts from "./profile/ProfilePosts";
import BackLink from "./BackLink";

const Search = () => {
    const [searchResults, setSearchResults] = useState({ users: [], posts: [], groups: [] });
    const [searchValue, setSearchValue] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { width } = useWindowSize();
    const [showSearchResults, setShowSearchResults] = useState(false);

    const handleSearch = async (e) => {
        e.preventDefault();

        if (searchValue.trim() === '') {
            return setSearchResults({ users: [], posts: [], groups: [] });
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                params: {
                    query: searchValue,
                    category: selectedCategory,
                },
            });
            setSearchResults(response.data);
            setShowSearchResults(true);
            setSearchValue('');
        } catch (error) {
            console.error(error);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        handleSearch(); // Опционально перезапустить поиск с новой категорией
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/search/categories`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`, // Проверить правильность токена
                    },
                });
                setCategories(response.data);
            } catch (error) {
                console.error(error);
            }
        };


        fetchCategories();
    }, []);

    if (width < 768) {
        return <BottomNav />;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <BackLink />
            <h1 className="text-3xl font-extrabold dark:text-white mb-6">Поиск</h1>
            <form onSubmit={handleSearch} className="mb-6">
                <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Поиск пользователя, поста или сообщества"
                    className="bg-white border border-gray-300 rounded-lg px-4 py-2 w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-600 transition duration-150 ease-in-out"
                />
                <button
                    type="submit"
                    className="mt-4 bg-violet-600 text-white rounded-lg px-4 py-2 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-600 transition duration-150 ease-in-out"
                >
                    Поиск
                </button>
            </form>
            <div className="mb-6">
                <div className="flex flex-wrap gap-2">
                    {categories.map((category) => (
                        <button
                            key={category.id}
                            onClick={() => handleCategoryClick(category.id)}
                            className={`py-2 px-4 rounded-lg font-medium transition duration-150 ease-in-out ${
                                selectedCategory === category.id
                                    ? 'bg-violet-600 text-white'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                            }`}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>
            </div>
            {showSearchResults && (
                <div>
                    {selectedCategory === 'users' && searchResults.users.length > 0 && (
                        <div className="mb-6">
                            <h3 className="text-xl font-semibold mb-2">Пользователи</h3>
                            {searchResults.users.map((user) => (
                                <div key={user.id} className="bg-white border border-gray-200 rounded-lg px-4 py-2 mb-2 shadow-sm hover:bg-gray-50 transition duration-150 ease-in-out">
                                    <Link to={`/users/${user.id}`} className="text-violet-600 hover:underline">
                                        {user.name}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                    {selectedCategory === 'posts' && searchResults.posts.length > 0 && (
                        <div className="mb-6">
                            <h3 className="text-xl font-semibold mb-2">Посты</h3>
                            {searchResults.posts.map((post) => (
                                <div key={post.id} className="bg-white border border-gray-200 rounded-lg px-4 py-2 mb-2 shadow-sm hover:bg-gray-50 transition duration-150 ease-in-out">
                                    <Link to={`/posts/${post.id}`} className="text-violet-600 hover:underline">
                                        {profilePosts(post)}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                    {selectedCategory === 'groups' && searchResults.groups.length > 0 && (
                        <div className="mb-6">
                            <h3 className="text-xl font-semibold mb-2">Группы</h3>
                            {searchResults.groups.map((group) => (
                                <div key={group.id} className="bg-white border border-gray-200 rounded-lg px-4 py-2 mb-2 shadow-sm hover:bg-gray-50 transition duration-150 ease-in-out">
                                    <Link to={`/groups/${group.id}`} className="text-violet-600 hover:underline">
                                        {group.name}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                    {!selectedCategory && (
                        <div>
                            {Object.entries(searchResults).map(([key, results]) => (
                                results.length > 0 && (
                                    <div key={key} className="mb-6">
                                        <h3 className="text-xl font-semibold mb-2">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                                        {results.map((result) => (
                                            <div key={result.id} className="bg-white border border-gray-200 rounded-lg px-4 py-2 mb-2 shadow-sm hover:bg-gray-50 transition duration-150 ease-in-out">
                                                <Link to={`/${key}/${result.id}`} className="text-violet-600 hover:underline">
                                                    {result.name || result.title}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Search;
