// ProfileInfo Component
import React, { useState } from 'react';
import axios from 'axios';
import FriendListForProfile from './FriendListForProfile';
import OnlineStatus from "../OnlineStatus";
import { motion } from 'framer-motion';
import AvatarGallery from "./AvatarGallery";
import ImageModal from './ImageModal';

function ProfileInfo({ user }) {
    const [description, setDescription] = useState(user.status || '');
    const [isEditing, setIsEditing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isImageModalOpen, setImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState(null);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setDescription(user.status || '');
    };

    const handleSaveClick = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/profile/update-status`,
                { status: description },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            user.status = response.data.status;
            setIsEditing(false);
        } catch (error) {
            console.error("Ошибка при обновлении описания профиля", error);
        }
    };

    const openImageModal = (src) => {
        setModalImageSrc(src);
        setImageModalOpen(true);
    };

    const closeImageModal = () => setImageModalOpen(false);

    return (
        <div className="text-center space-y-6 m-0 p-0">
            <motion.p
                className="text-2xl font-semibold text-gray-900 dark:text-gray-100"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                {user.name}
            </motion.p>
            {isEditing ? (
                <motion.div
                    className="p-4 rounded-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full p-2 border border-purple-300 rounded-md dark:bg-neutral-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                        rows="3"
                        placeholder="Введите описание профиля..."
                    />
                    <div className="flex space-x-2 mt-2 justify-center">
                        <button
                            onClick={handleSaveClick}
                            className="bg-purple-600 text-white rounded-md px-4 py-2 hover:bg-purple-700 transition duration-200"
                        >
                            Сохранить
                        </button>
                        <button
                            onClick={handleCancelClick}
                            className="bg-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-400 transition duration-200"
                        >
                            Отмена
                        </button>
                    </div>
                </motion.div>
            ) : (
                <div
                    className="relative"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <motion.p
                        className="text-gray-600 dark:text-gray-400 text-sm"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        {user.status || 'Описание отсутствует'}
                        {isHovered && (
                            <motion.button
                                onClick={handleEditClick}
                                className="ml-2 text-purple-600 hover:text-purple-700 transition duration-200"
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.2 }}
                            >
                                <i className="fas fa-edit"></i>
                            </motion.button>
                        )}
                    </motion.p>
                </div>
            )}

            <AvatarGallery avatars={user.avatars} onImageClick={openImageModal} />

            {isImageModalOpen && (
                <ImageModal src={modalImageSrc} alt="Аватар" onClose={closeImageModal} />
            )}

            <motion.p
                className="text-gray-800 dark:text-gray-400 text-sm flex items-center justify-center gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <i className="fas fa-envelope"></i>{user.email}
            </motion.p>

            <FriendListForProfile />
        </div>
    );
}

export default ProfileInfo;
