import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import useWindowSize from "../hooks/useWindowSize";

const DeletePost = ({ show, onClose, onDelete, postId }) => {  // Accept postId as a prop
    const { width } = useWindowSize();
    const modalRef = useRef(null);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const handleTouchMove = (e) => {
        if (modalRef.current) {
            const touchY = e.touches[0].clientY;
            if (touchY > window.innerHeight * 0.9) {
                onClose();
            }
        }
    };

    if (!show) return null;

    const overlayVariants = {
        visible: { opacity: 1, backdropFilter: 'blur(5px)' },
        hidden: { opacity: 0, backdropFilter: 'blur(0px)' },
    };

    const mobileModalVariants = {
        open: { y: 0 },
        closed: { y: '100%' },
    };

    const desktopModalVariants = {
        open: { opacity: 1, scale: 1 },
        closed: { opacity: 0, scale: 0.9 },
    };

    return (
        <>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-40 z-40"
                initial="hidden"
                animate={show ? "visible" : "hidden"}
                variants={overlayVariants}
                transition={{ duration: 0.3 }}
                onClick={onClose}
            ></motion.div>

            {width < 768 ? (
                <motion.div
                    ref={modalRef}
                    className="fixed bottom-0 left-0 w-full bg-white shadow-lg rounded-t-2xl dark:bg-neutral-800 z-50"
                    initial="closed"
                    animate={show ? "open" : "closed"}
                    variants={mobileModalVariants}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    onTouchMove={handleTouchMove}
                >
                    <div className="p-6">
                        <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-5"></div>
                        <h2 className="text-xl font-semibold text-center mb-4">Подтверждение удаления</h2>
                        <p className="text-center text-gray-600 mb-6 dark:text-gray-300">Вы уверены, что хотите удалить этот пост?</p>
                        <div className="flex justify-between">
                            <button
                                onClick={onClose}
                                className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition-colors"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={() => onDelete(postId)}  // Ensure postId is passed here
                                className="ml-3 w-full px-4 py-2 text-red-500 border border-transparent hover:bg-red-100 dark:hover:bg-red-600/10 rounded-lg transition-all duration-300 ease-in-out"
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </motion.div>
            ) : (
                <motion.div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50"
                    initial="closed"
                    animate={show ? "open" : "closed"}
                    variants={overlayVariants}
                    transition={{ duration: 0.3 }}
                >
                    <motion.div
                        className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-md dark:bg-neutral-800"
                        variants={desktopModalVariants}
                        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    >
                        <h2 className="text-xl font-semibold text-center mb-5">Подтверждение удаления</h2>
                        <p className="text-center text-gray-600 mb-6 dark:text-gray-300">Вы уверены, что хотите удалить этот пост?</p>
                        <div className="flex justify-between">
                            <button
                                onClick={onClose}
                                className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition-colors"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={() => onDelete(postId)}  // Ensure postId is passed here
                                className="ml-3 w-full px-4 py-2 text-red-500 border border-transparent hover:bg-red-100 dark:hover:bg-red-600/10 rounded-lg transition-all duration-300 ease-in-out"
                            >
                                Удалить
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </>
    );
};

export default DeletePost;
