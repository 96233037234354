import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { FaHashtag, FaUsers } from "react-icons/fa";
import { motion } from 'framer-motion';

function RightSidebar({ groupId }) {
    const [recommendedGroups, setRecommendedGroups] = useState(null);
    const trendingTopics = [
        '#ReactJS',
        '#ВебРазработка',
        '#ИскусственныйИнтеллект',
        '#Дизайн',
        '#Фитнес',
        '#Фотография',
        '#Музыка',
        '#Технологии',
    ];

    const fetchRecommendedGroups = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}/recommendations`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setRecommendedGroups(response.data);
        } catch (error) {
            console.error('Error fetching recommended groups:', error);
            setRecommendedGroups([]); // Пустой массив, если произошла ошибка
        }
    };

    useEffect(() => {
        fetchRecommendedGroups();
    }, []);

    return (
        <aside className="w-full lg:w-1/3 p-6 mt-2 space-y-8">
            <motion.section
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="rounded-3xl p-4 shadow-lg text-gray-800 dark:text-white bg-white dark:bg-gradient-to-r from-purple-500 to-purple-700"
            >
                <div className="flex items-center mb-4">
                    <FaHashtag className="text-2xl text-purple-500 dark:text-white mr-2" />
                    <h2 className="text-xl font-bold">Тренды</h2>
                </div>
                <div className="flex flex-wrap gap-3">
                    {trendingTopics.map((topic, index) => (
                        <span
                            key={index}
                            className="px-4 py-2 text-sm font-medium rounded-full bg-purple-100 text-purple-600 dark:bg-purple-300 dark:text-purple-600 dark:hover:bg-purple-200 hover:bg-purple-200 cursor-pointer transition-all duration-300"
                        >
                            {topic}
                        </span>
                    ))}
                </div>
            </motion.section>

            {recommendedGroups !== null && recommendedGroups.length > 0 && (
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="bg-white dark:bg-neutral-800 rounded-3xl p-4 shadow-lg"
                >
                    <div className="flex items-center mb-4">
                        <h2 className="text-xl font-bold text-gray-800 dark:text-gray-100">Рекомендованные группы</h2>
                    </div>
                    <ul className="space-y-4">
                        {recommendedGroups.map((group) => (
                            <li
                                key={group.id}
                                className="group"
                            >
                                <Link
                                    to={`/group/${group.id}`}
                                    className="flex items-center p-2 rounded-lg bg-gray-50 dark:bg-neutral-800 hover:bg-purple-50 dark:hover:bg-neutral-700 transition-colors duration-150 shadow-sm"
                                >
                                    <img
                                        src={group.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${group.avatar}` : 'https://via.placeholder.com/150'}
                                        alt={group.name}
                                        className="w-10 h-10 rounded-full border border-gray-200 dark:border-neutral-700 mr-4"
                                    />
                                    <div className="flex-1">
                                        <span className="text-lg font-semibold text-gray-900 dark:text-gray-200">{group.name}</span>
                                        <p className="text-xs text-gray-500">{group.members_count} подписчиков</p>
                                    </div>
                                    <button className="ml-auto px-2 py-1 text-sm font-medium border border-purple-500 text-purple-500 rounded-full hover:bg-purple-500 hover:text-white transition duration-150">
                                        Подписаться
                                    </button>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </motion.section>
            )}

            {recommendedGroups === null && (
                <motion.section
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="bg-white dark:bg-neutral-800 rounded-3xl p-4 shadow-lg"
                >
                    <div className="flex items-center mb-4">
                        <h2 className="text-xl font-bold text-gray-800 dark:text-gray-100">Рекомендованные группы</h2>
                    </div>
                    <ul className="space-y-4">
                        {Array(3).fill().map((_, index) => (
                            <li key={index} className="flex items-center p-2 rounded-lg bg-gray-100 dark:bg-neutral-700 transition-colors duration-150 shadow-sm">
                                <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-neutral-600 mr-4"></div>
                                <div className="flex-1 space-y-2">
                                    <div className="w-3/4 h-4 bg-gray-200 dark:bg-neutral-600 rounded"></div>
                                    <div className="w-1/2 h-3 bg-gray-200 dark:bg-neutral-600 rounded"></div>
                                </div>
                                <div className="ml-auto w-16 h-6 bg-gray-200 dark:bg-neutral-600 rounded-full"></div>
                            </li>
                        ))}
                    </ul>
                </motion.section>
            )}
        </aside>
    );
}

export default RightSidebar;
