import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Post from './Post'; // Компонент для отображения поста
import { ClipLoader } from 'react-spinners'; // Спиннер для загрузки

function GroupPostList({ groupId }) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchGroupPosts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}/posts`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (Array.isArray(response.data)) {
                setPosts(response.data);
            } else {
                console.error('Unexpected response data format:', response.data);
            }
        } catch (error) {
            console.error('Ошибка при загрузке постов группы', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (groupId) {
            fetchGroupPosts();
        }
    }, [groupId]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <ClipLoader color="#6B46C1" loading={loading} size={75} />
            </div>
        );
    }

    if (posts.length === 0) {
        return (
            <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
                <p className="text-center text-gray-700 dark:text-white">В этой группе нет постов.</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto rounded-lg min-h-screen">
            <div className="space-y-6">
                {posts.map((post) => (
                    <Post key={post.id} post={post} />
                ))}
            </div>
        </div>
    );
}

export default GroupPostList;
