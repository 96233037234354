import React from 'react';

const wallpapers = [
    './build/bg/gr1962_1920_1080.png',
    './build/bg/gr1951_1920_1080.png',
    './build/bg/gr1923_1920_1080.png',
    './build/bg/gr1922_1920_1080.png',
    './build/bg/gr1915_1920_1080.png',
    './build/bg/gr1943_1920_1080.png',
    './build/bg/gr1877_1920_1080.png',
    './build/bg/gr1755_1920_1080.png',
    './build/bg/gr1822_1920_1080.png',
    './build/bg/gr1245_1920_1080.png',
];

function WallpaperModal({ onClose, onChange }) {
    const handleWallpaperClick = (wallpaper) => {
        onChange(wallpaper);
        onClose();
    };

    const resetToDefault = () => {
        onChange(null);
        onClose();
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="bg-white dark:bg-neutral-900 rounded-xl shadow-2xl p-6 w-[90%] max-w-xl">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-6 text-center">Выберите обои</h2>
                <div className="grid grid-cols-4 gap-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 mb-6">
                    {wallpapers.map((wallpaper, index) => (
                        <div
                            key={index}
                            className="relative overflow-hidden rounded-xl shadow-lg cursor-pointer hover:scale-105 transition-transform duration-300"
                            onClick={() => handleWallpaperClick(wallpaper)}
                        >
                            <img
                                src={wallpaper}
                                alt={`Wallpaper ${index + 1}`}
                                className="object-cover w-full h-24 sm:h-32 md:h-40 lg:h-48"
                            />
                        </div>
                    ))}
                </div>
                <div className="flex justify-between space-x-4">
                    <button
                        onClick={resetToDefault}
                        className="flex-1 px-6 py-3 bg-red-500 hover:bg-red-600 text-white rounded-lg shadow-lg transition duration-300"
                    >
                        Сбросить обои
                    </button>
                    <button
                        onClick={onClose}
                        className="flex-1 px-6 py-3 bg-gray-300 hover:bg-gray-400 dark:bg-neutral-700 dark:hover:bg-neutral-600 text-black dark:text-white rounded-lg shadow-lg transition duration-300"
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    );
}

export default WallpaperModal;
