import React from 'react';

const CustomSkeleton = ({ width, height, circle }) => {
    const baseClasses = "animate-pulse bg-gray-300 rounded my-1.5 dark:bg-neutral-700";
    const shapeClasses = circle
        ? `rounded-full w-${width} h-${height}`
        : `rounded w-${width} h-${height}`;

    return (
        <div className={`${baseClasses} ${shapeClasses}`} style={{ width, height }}></div>
    );
};

export default CustomSkeleton;
