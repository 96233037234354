import React, { useEffect, useState } from "react";
import BackLink from "../BackLink";
import { ClipLoader } from "react-spinners";
import { motion, AnimatePresence } from "framer-motion";

export default function WeatherDetails() {
    const [city, setCity] = useState("");
    const [weather, setWeather] = useState(null);
    const [forecast, setForecast] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expandedDay, setExpandedDay] = useState(null);

    const API_KEY = "f271c589dc595252feddf5cae33f1718";

    const fetchWeatherData = async (cityName) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${cityName}&units=metric&appid=${API_KEY}&lang=ru`);
            if (!response.ok) throw new Error("Не удалось найти город");
            const data = await response.json();
            setWeather(data);
            fetchForecastData(data.coord.lat, data.coord.lon);
        } catch (err) {
            setError(err.message);
            setWeather(null);
        } finally {
            setLoading(false);
        }
    };

    const fetchForecastData = async (latitude, longitude) => {
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&units=metric&appid=${API_KEY}&lang=ru`);
            if (!response.ok) throw new Error("Не удалось загрузить прогноз");
            const data = await response.json();
            const dailyForecast = data.list.filter((item) => item.dt_txt.includes("12:00:00"));
            setForecast(dailyForecast);
        } catch (err) {
            console.error("Ошибка при загрузке прогноза:", err);
        }
    };

    const fetchCityByGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchWeatherByGeolocation(latitude, longitude);
                },
                () => setError("Геолокация не разрешена или недоступна")
            );
        } else {
            setError("Геолокация не поддерживается браузером");
        }
    };

    const fetchWeatherByGeolocation = async (latitude, longitude) => {
        setLoading(true);
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${API_KEY}&lang=ru`);
            if (!response.ok) throw new Error("Не удалось определить город по геопозиции");
            const data = await response.json();
            setCity(data.name);
            setWeather(data);
            fetchForecastData(latitude, longitude);
        } catch (err) {
            setError("Ошибка при получении данных по геопозиции");
            setWeather(null);
        } finally {
            setLoading(false);
        }
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" });
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString("ru-RU", { weekday: "long", day: "numeric", month: "long" });
    };

    useEffect(() => {
        fetchCityByGeolocation();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (city) fetchWeatherData(city);
    };

    const toggleDayDetails = (day) => {
        setExpandedDay((prev) => (prev === day.dt ? null : day.dt));
    };

    return (
        <div className="container mx-auto p-4 min-h-screen text-neutral-900 dark:text-neutral-100 transition-colors duration-300">
            <BackLink />
            <h1 className="text-4xl font-extrabold text-center text-gray-900 dark:text-gray-100 mb-6">
                🌦️ Погода
            </h1>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (city) fetchWeatherData(city);
                }}
                className="flex flex-col items-center mb-8 space-y-4"
            >
                <input
                    type="text"
                    placeholder="Введите город"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="w-3/4 md:w-1/2 px-4 py-3 rounded-lg bg-white dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100 shadow focus:outline-none border border-neutral-300 dark:border-neutral-700"
                />
                <button
                    type="submit"
                    className={`px-6 py-3 rounded-lg font-bold text-lg transition-all duration-300 ${
                        loading
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-purple-600 hover:bg-purple-700 dark:bg-purple-500 dark:hover:bg-purple-600"
                    } text-white`}
                    disabled={loading}
                >
                    {loading ? "Загрузка..." : "Найти"}
                </button>
            </form>
            {loading && <div className="text-center"><ClipLoader color="#36d7b7" size={50} /></div>}
            <AnimatePresence>
                {error && (
                    <motion.p
                        className="text-center text-xl text-red-500"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                    >
                        {error}
                    </motion.p>
                )}
                {weather && (
                    <motion.div
                        className="bg-white dark:bg-neutral-800 rounded-lg shadow-lg p-6 text-center mb-8"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 20}}
                        transition={{duration: 0.5}}
                    >
                        <motion.img
                            src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@4x.png`}
                            alt="Иконка погоды"
                            className="w-32 h-32 mx-auto mb-4"
                            initial={{scale: 0.8}}
                            animate={{scale: 1}}
                            transition={{duration: 0.5, type: "spring"}}
                        />
                        <h2 className="text-3xl font-bold mb-2 text-purple-600 dark:text-purple-400">{weather.name}</h2>
                        <p className="text-xl mb-4 text-neutral-700 dark:text-neutral-300">
                            {weather.main.temp}°C, {weather.weather[0].description}
                        </p>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="text-center">
                                <p className="text-lg text-neutral-600 dark:text-neutral-400">💧 Влажность</p>
                                <p className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">{weather.main.humidity}%</p>
                            </div>
                            <div className="text-center">
                                <p className="text-lg text-neutral-600 dark:text-neutral-400">🌬️ Скорость ветра</p>
                                <p className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">{weather.wind.speed} м/с</p>
                            </div>
                            <div className="text-center">
                                <p className="text-lg text-neutral-600 dark:text-neutral-400">🌡️ Ощущается как</p>
                                <p className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">{weather.main.feels_like}°C</p>
                            </div>
                            <div className="text-center">
                                <p className="text-lg text-neutral-600 dark:text-neutral-400">📏 Давление</p>
                                <p className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">{weather.main.pressure} гПа</p>
                            </div>
                            <div className="text-center col-span-2">
                                <p className="text-lg text-neutral-600 dark:text-neutral-400">🌅 Восход / Закат</p>
                                <p className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">
                                    {formatTime(weather.sys.sunrise)} / {formatTime(weather.sys.sunset)}
                                </p>
                            </div>
                        </div>
                    </motion.div>
                )}
                {forecast.length > 0 && (
                    <div className="space-y-8">
                        <h2 className="text-4xl font-semibold text-gray-900 dark:text-gray-50">
                            Прогноз погоды
                        </h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {forecast.map((day) => (
                                <motion.div
                                    key={day.dt}
                                    className="rounded-lg bg-white dark:bg-neutral-800 shadow-md hover:shadow-xl transition-all transform hover:-translate-y-1"
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.4 }}
                                >
                                    <div
                                        className="p-5 cursor-pointer"
                                        onClick={() => toggleDayDetails(day)}
                                    >
                                        <motion.div
                                            className="flex justify-between items-center"
                                            whileHover={{ scale: 1.01 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <div>
                                                <p className="text-lg font-medium text-gray-800 dark:text-gray-100">
                                                    {formatDate(day.dt)}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 capitalize">
                                                    {day.weather[0].description}
                                                </p>
                                            </div>
                                            <img
                                                src={`http://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`}
                                                alt={day.weather[0].description}
                                                className="w-12 h-12"
                                            />
                                        </motion.div>

                                        <div className="flex justify-between items-center mt-4">
                                            <p className="text-3xl font-bold text-gray-800 dark:text-gray-100">
                                                {day.main.temp}°C
                                            </p>
                                            <motion.button
                                                className="text-violet-600 dark:text-violet-400 flex items-center"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleDayDetails(day);
                                                }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                {expandedDay === day.dt ? "Скрыть" : "Подробнее"}
                                                <i
                                                    className={`ml-2 fas ${
                                                        expandedDay === day.dt ? "fa-chevron-up" : "fa-chevron-down"
                                                    }`}
                                                />
                                            </motion.button>
                                        </div>
                                    </div>

                                    <motion.div
                                        className="bg-neutral-50 dark:bg-neutral-800 px-5 py-4 rounded-b-lg overflow-hidden"
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{
                                            opacity: expandedDay === day.dt ? 1 : 0,
                                            height: expandedDay === day.dt ? "auto" : 0,
                                        }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{
                                            duration: 0.5,
                                            ease: "easeInOut",
                                        }}
                                    >
                                        <div className="grid grid-cols-2 gap-4 text-sm text-gray-700 dark:text-gray-300">
                                            <p>💧 <strong>Влажность:</strong> {day.main.humidity}%</p>
                                            <p>🌬️ <strong>Ветер:</strong> {day.wind.speed} м/с</p>
                                            <p>📏 <strong>Давление:</strong> {day.main.pressure} гПа</p>
                                            <p>🌡️ <strong>Ощущается как:</strong> {day.main.feels_like}°C</p>
                                        </div>
                                    </motion.div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                )}
                        </AnimatePresence>
        </div>
    );
}