import React from 'react';
import BackLink from "../BackLink";

function ProfileHeader({ isLoggedIn, handleLogout }) {
    return (
        <header className="flex justify-between items-center py-4 px-2 bg-white dark:bg-neutral-900 shadow-md rounded-b-lg border-b border-neutral-300 dark:border-neutral-700">
            <BackLink />
            {isLoggedIn && (
                <button
                    onClick={handleLogout}
                    className="flex items-center text-red-500 hover:text-red-600 transition duration-200 focus:outline-none">
                    <i className="fas fa-sign-out-alt mr-2"></i>
                    <span className="font-semibold">Выйти</span>
                </button>
            )}
        </header>
    );
}

export default ProfileHeader;
