import React from 'react';

function ImageModal({ src, alt, onClose }) {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-white dark:bg-neutral-900 rounded-lg shadow-lg relative max-w-2xl mx-auto w-full h-full p-4">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 dark:text-white dark:hover:text-violet-400 transition duration-200"
                >
                    <i className="fas fa-times fa-lg"></i>
                </button>
                <img
                    src={src}
                    alt={alt}
                    className="w-full h-full object-contain rounded-lg"
                />
            </div>
        </div>
    );
}

export default ImageModal;
