import React, { useState } from 'react';
import UpdateAvatar from './UpdateAvatar';
import ImageModal from './ImageModal';

function ProfileAvatar({ user, showModal, setShowModal }) {
    const defaultAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';
    const avatarSrc = user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}` : defaultAvatar;

    const [isImageModalOpen, setImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState(null);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const openImageModal = (src) => {
        setModalImageSrc(src);
        setImageModalOpen(true);
    };
    const closeImageModal = () => setImageModalOpen(false);

    return (
        <div className="flex flex-col items-center p-4 dark:bg-neutral-900 rounded-lg">
            {showModal && <UpdateAvatar user={user} closeModal={closeModal} />}
            {isImageModalOpen && (
                <ImageModal src={modalImageSrc} alt="Аватар" onClose={closeImageModal} />
            )}

            {/* Текущий аватар с кнопкой обновления */}
            <div className="relative mb-4">
                <img
                    src={avatarSrc}
                    alt="Аватар"
                    className="w-32 h-32 object-cover rounded-full border-4 border-gray-300 dark:border-gray-600 transition-transform duration-200 hover:scale-105 cursor-pointer"
                    onClick={() => openImageModal(avatarSrc)}
                />
                <button
                    onClick={openModal}
                    className="absolute bottom-0 right-0 bg-violet-600 text-white rounded-full w-7 h-7 shadow-md transition duration-300 hover:bg-violet-700"
                >
                    <i className="fas fa-plus"></i>
                </button>
            </div>
        </div>
    );
}

export default ProfileAvatar;
