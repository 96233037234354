import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';

const notifySuccess = () => toast.success('Регистрация прошла успешно!');
const notify = () => toast.error('Произошла ошибка при регистрации! Проверьте правильность введенных данных!');

function Register() {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const navigate = useNavigate();

  const handleNextStep = (e) => {
    e.preventDefault();
    if (name && email) {
      setStep(2);
    } else {
      toast.error('Пожалуйста, заполните все поля.');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Пароли не совпадают!');
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
        name,
        email,
        password,
      });
      console.log(response.data);
      notifySuccess();
      navigate('/login');
    } catch (error) {
      notify();
      console.error('There was an error!', error);
    }
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    return strength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(calculatePasswordStrength(newPassword));
  };

  // Настройки анимации для плавных переходов
  const variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
      <div className="relative w-full h-screen flex items-center justify-center bg-gray-100 dark:bg-neutral-900">
        <div className="absolute inset-0 z-0">
          <div className="absolute top-10 left-10 w-48 h-48 bg-purple-400 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-blob1"></div>
          <div className="absolute top-1 right-1/3 w-48 h-48 dark:w-20 dark:h-20 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob2 animation-delay-1000"></div>
          <div className="absolute bottom-1/2 right-10 w-40 h-40 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob3 animation-delay-3000"></div>
          <div className="absolute bottom-1 left-1 w-16 h-16 bg-purple-400 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob4 animation-delay-2000"></div>
          <div className="absolute top-1/2 left-1/4 w-48 h-48 bg-purple-400 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob1 animation-delay-2000"></div>
          <div className="absolute bottom-10 left-1 w-40 h-40 dark:w-20 dark:h-24 bg-violet-300 rounded-full mix-blend-multiply filter blur-2xl opacity-60 animate-blob2 animation-delay-3000"></div>
          <div className="absolute bottom-10 right-1/4 w-48 h-48 dark:w-20 dark:h-24 bg-purple-300 rounded-full mix-blend-multiply filter blur-2xl opacity-60 animate-blob3 animation-delay-1000"></div>
        </div>

        <div
            className="relative z-10 w-full max-w-6xl p-8 bg-white dark:bg-neutral-800 rounded-xl shadow-lg flex flex-col lg:flex-row lg:space-x-6">
          <div className="flex-shrink-0 mb-6 lg:mb-0 lg:w-1/2">
            <iframe src="https://lottie.host/embed/4cb611b9-7445-4ce2-b5ec-43cbab7094e1/M7eB7NTTm5.json"
            className="w-full h-full rounded-lg"
            loading="eager"
            >
          </iframe>
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <h2 className="text-4xl font-extrabold mb-6 text-center text-gray-900 dark:text-white">
              Регистрация
            </h2>

            {/* Первый шаг */}
            {step === 1 && (
                <motion.form
                    onSubmit={handleNextStep}
                    className="flex flex-col space-y-4"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={variants}
                    transition={{ duration: 0.5 }}
                >
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Имя</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg dark:border-neutral-700 dark:bg-neutral-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
                        placeholder="Введите ваше полное имя"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg dark:border-neutral-700 dark:bg-neutral-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
                        placeholder="Введите ваш адрес email"
                    />
                  </div>
                  <button
                      type="submit"
                      className="w-full py-3 px-6 text-lg font-semibold text-white bg-indigo-600 rounded-lg shadow-md hover:bg-indigo-700 transition-all duration-300"
                  >
                    Далее
                  </button>
                </motion.form>
            )}

            {/* Второй шаг */}
            {step === 2 && (
                <motion.form
                    onSubmit={handleRegister}
                    className="flex flex-col space-y-4"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={variants}
                    transition={{ duration: 0.5 }}
                >
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Пароль</label>
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg dark:border-neutral-700 dark:bg-neutral-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
                        placeholder="Введите ваш пароль"
                    />
                    {/* Индикатор силы пароля */}
                    <div className="mt-2">
                      <div className={`h-2 rounded-full transition-all duration-300 ${
                          passwordStrength >= 4 ? 'bg-green-500' :
                              passwordStrength >= 3 ? 'bg-yellow-500' :
                                  passwordStrength >= 2 ? 'bg-orange-500' :
                                      'bg-red-500'
                      }`} style={{ width: `${(passwordStrength / 5) * 100}%` }}></div>
                      <p className="text-xs mt-1 text-gray-600 dark:text-gray-400">
                        {passwordStrength >= 4 ? 'Сильный пароль' :
                            passwordStrength >= 3 ? 'Средний пароль' :
                                passwordStrength >= 2 ? 'Слабый пароль' :
                                    'Очень слабый пароль'}
                      </p>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Повторите пароль</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg dark:border-neutral-700 dark:bg-neutral-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
                        placeholder="Повторите ваш пароль"
                    />
                  </div>
                  <button
                      type="submit"
                      className="w-full py-3 px-6 text-lg font-semibold text-white bg-indigo-600 rounded-lg shadow-md hover:bg-indigo-700 transition-all duration-300"
                  >
                    Зарегистрироваться
                  </button>
                </motion.form>
            )}

            <div className="mt-6 text-center">
              <p className="text-gray-700 dark:text-gray-300">
                Уже есть аккаунт? <Link to="/login" className="text-indigo-500 hover:text-indigo-600 dark:text-indigo-400 dark:hover:text-indigo-500 transition duration-300">Войти!</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Register;
