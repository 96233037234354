import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      axios.get(`${process.env.REACT_APP_API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
          .then((response) => {
            if (response.data && typeof response.data === 'object') {
              setUser(response.data);
              setIsLoggedIn(true);
            } else {
              console.warn("Unexpected user data format:", response.data);
              setIsLoggedIn(false);
              setUser(null);
            }
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
            setIsLoggedIn(false);
            setUser(null);
          });
    }

    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    axios.get(`${process.env.REACT_APP_API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
        .then(response => {
          if (response.data && typeof response.data === 'object') {
            setUser(response.data);
            setIsLoggedIn(true);
          } else {
            console.warn("Unexpected user data format:", response.data);
            setUser(null);
            setIsLoggedIn(false);
          }
        })
        .catch(error => {
          console.error("Error fetching user data:", error);
          setIsLoggedIn(false);
          setUser(null);
        });
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
  };

  return (
      <AuthContext.Provider value={{ isLoggedIn, user, login, logout, setUser }}>
        {children}
      </AuthContext.Provider>
  );
};
