import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

function SharePostModal({ post, onClose, onSend }) {
    const [chats, setChats] = useState([]);
    const [filteredChats, setFilteredChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true); // Модалка открыта при рендере

    useEffect(() => {
        const fetchChats = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/chats`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setChats(response.data);
                setFilteredChats(response.data);
            } catch (error) {
                console.error('Ошибка при получении чатов', error);
            }
        };
        fetchChats();
    }, []);

    const handleSendPost = async () => {
        const token = localStorage.getItem('token');
        if (!selectedChatId) {
            toast.error('Пожалуйста, выберите чат.');
            return;
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/posts/${post.id}/send`,
                {
                    chat_id: selectedChatId,
                    post_id: post.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            onSend();
            toast.success('Пост успешно отправлен!');
            setIsModalOpen(false);
            onClose();
        } catch (error) {
            console.error('Ошибка при отправке поста', error);
            toast.error('Ошибка при отправке поста.');
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilteredChats(chats.filter(chat =>
            chat.user1.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            chat.user2.name.toLowerCase().includes(e.target.value.toLowerCase())
        ));
    };

    const getChatPartner = (chat) => {
        const userId = parseInt(localStorage.getItem('user_id'), 10);
        // Определяем партнера по чату на основе текущего пользователя
        return chat.user1_id === userId ? chat.user2 : chat.user1;
    };

    const getAvatarUrl = (chat) => {
        const partner = getChatPartner(chat);
        // Проверяем, есть ли у партнера аватар, иначе возвращаем заглушку
        return partner.avatar
            ? `${process.env.REACT_APP_BASE_URL}/storage/${partner.avatar}`
            : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAd5avdba8EiOZH8lmV3XshrXx7dKRZvhx-A&s';
    };

    return (
        <AnimatePresence>
            {isModalOpen && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.div
                        className="bg-white dark:bg-neutral-800 p-6 rounded-lg shadow-lg max-w-lg w-full relative"
                        initial={{ scale: 0.9, y: 50 }}
                        animate={{ scale: 1, y: 0 }}
                        exit={{ scale: 0.9, y: 50 }}
                        transition={{ type: 'spring', stiffness: 200, damping: 20 }}
                    >
                        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white">
                            <i className="fas fa-times text-2xl"></i>
                        </button>
                        <h2 className="text-3xl font-extrabold text-gray-800 mb-6 dark:text-white text-center">Поделиться постом</h2>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Поиск чатов..."
                            className="w-full mb-4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-violet-500 dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                        />
                        <div className="grid grid-cols-1 gap-4 mb-4 max-h-60 overflow-y-auto">
                            {filteredChats.map(chat => (
                                <motion.div
                                    key={chat.id}
                                    className={`flex items-center p-4 rounded-lg cursor-pointer border transition-transform duration-200 transform hover:scale-105 ${selectedChatId === chat.id ? 'border-violet-500 bg-violet-100 dark:bg-violet-700' : 'border-gray-300 dark:border-gray-600'}`}
                                    onClick={() => setSelectedChatId(chat.id)}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 20 }}
                                >
                                    <img
                                        src={getAvatarUrl(chat)}
                                        alt="Avatar"
                                        className="w-12 h-12 rounded-full mr-4 object-cover"
                                    />
                                    <div className="flex flex-col">
                                        <p className="font-semibold text-gray-800 dark:text-white">{getChatPartner(chat).name}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={handleSendPost}
                                className={`bg-violet-500 hover:bg-violet-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ${!selectedChatId ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={!selectedChatId}
                            >
                                Отправить пост
                            </button>
                            <button
                                onClick={onClose}
                                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ml-2"
                            >
                                Отмена
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default SharePostModal;
