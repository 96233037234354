import React, { useState, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import AvatarEditor from 'react-avatar-editor';

const UpdateAvatar = ({ closeModal }) => {
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(1);
  const [originalAvatar, setOriginalAvatar] = useState(null);
  const { user, setUser } = useContext(AuthContext);
  const editorRef = useRef();

  React.useEffect(() => {
    setOriginalAvatar(user.avatar);
  }, [user.avatar]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']; // Добавлено поддержка GIF
    if (!allowedTypes.includes(file.type)) {
      return toast.error('Неподдерживаемый формат файла! Используйте JPG, PNG или GIF.');
    }

    if (file.size > 5 * 1024 * 1024) {
      return toast.error('Размер файла превышает 5MB!');
    }

    setAvatar(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!avatar) {
      return toast.error('Пожалуйста, выберите изображение.');
    }

    setIsLoading(true);
    toast.loading('Загрузка...');

    const token = localStorage.getItem('token');
    const formData = new FormData();

    if (editorRef.current) {
      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
        formData.append('avatar', blob);
        uploadAvatar(token, formData);
      }, 'image/jpeg', 0.92);
    }
  };

  const uploadAvatar = async (token, formData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/update-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setUser(response.data.user);
      toast.success('Аватар изменен успешно!');
      closeModal();
    } catch (error) {
      toast.error('Произошла ошибка при изменении аватара!');
      console.error('Ошибка при загрузке аватара!', error);
    } finally {
      setIsLoading(false);
      toast.dismiss();
    }
  };

  const handleCancel = () => {
    setAvatar(null);
    setPreview(originalAvatar);
    setScale(1);
  };

  const handleDeleteAvatar = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-avatar`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUser((prevUser) => ({
        ...prevUser,
        avatar: null,
      }));
      toast.success('Аватар удален успешно!');
      closeModal();
    } catch (error) {
      toast.error('Произошла ошибка при удалении аватара!');
      console.error('Ошибка при удалении аватара!', error);
    }
  };

  const handleScaleChange = (e) => {
    setScale(parseFloat(e.target.value));
  };

  return (
      <Modal
          isOpen={true}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg max-w-md w-full dark:bg-neutral-900 dark:text-white "
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          onRequestClose={closeModal}
      >
        <button onClick={closeModal} className="absolute top-4 right-4 text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400">
          <i className="fas fa-times text-xl cursor-pointer"></i>
        </button>
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center dark:text-white">Изменить аватар</h2>

        {user.avatar && (
            <div className="mb-6 text-center">
              <p className="text-gray-700 font-semibold mb-2">Текущий аватар:</p>
              <img src={user.avatar} alt="Current Avatar" className="w-32 h-32 rounded-full object-cover mx-auto" />
            </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-semibold mb-2 dark:text-white">Выберите новый аватар:</label>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-violet-400 transition duration-150"
            />
            {preview && (
                <button type="button" onClick={handleCancel} className="text-red-500 hover:text-red-700 mt-2 block">
                  Отменить изменения
                </button>
            )}
          </div>

          {preview && (
              <div className="mb-4 text-center">
                <p className="text-gray-700 font-semibold mb-2">Предварительный просмотр:</p>
                <AvatarEditor
                    ref={editorRef}
                    image={preview}
                    width={300}
                    height={300}
                    border={50}
                    color={[255, 255, 255, 0.6]}
                    scale={scale}
                    rotate={0}
                    className="rounded-full mx-auto"
                />
              </div>
          )}

          {preview && (
              <div>
                <label className="block text-gray-700 font-semibold mb-2">Масштаб:</label>
                <input
                    type="range"
                    min="1"
                    max="2"
                    step="0.01"
                    value={scale}
                    onChange={handleScaleChange}
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-violet-400 transition duration-150 active:scale-[0.98] "
                />
              </div>
          )}

          <button
              type="submit"
              className={`w-full bg-violet-500 hover:bg-violet-600 text-white font-semibold py-2 rounded-lg transition duration-200 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading}
          >
            {isLoading ? 'Загрузка...' : 'Сохранить'}
          </button>
        </form>

        <button
            onClick={handleDeleteAvatar}
            className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 rounded-lg mt-4 transition duration-200"
            disabled={isLoading}
        >
          Удалить аватар
        </button>
      </Modal>
  );
};

export default UpdateAvatar;
