import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import useWindowSize from '../../hooks/useWindowSize';

const notifyError = () => toast.error('Произошла ошибка при обработке запроса!');

function FriendRequests() {
    const [requests, setRequests] = useState([]);
    const { isLoggedIn } = useContext(AuthContext);
    const { width } = useWindowSize(); // Отслеживаем ширину экрана

    const fetchRequests = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/friend-requests`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRequests(response.data);
        } catch (error) {
            notifyError();
            console.error('There was an error fetching the friend requests!', error);
        }
    };

    const handleAccept = async (id) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/friends/accept/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            fetchRequests();
        } catch (error) {
            notifyError();
            console.error('There was an error accepting the friend request!', error);
        }
    };

    const handleReject = async (id) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/friends/reject/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            fetchRequests();
        } catch (error) {
            notifyError();
            console.error('There was an error rejecting the friend request!', error);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchRequests();
        }
    }, [isLoggedIn]);

    if (!isLoggedIn) {
        return <p className="text-center text-red-500 mt-4">Please log in to view friend requests.</p>;
    }

    return (
        <div className="mx-auto bg-white p-4 rounded-lg shadow-md mt-4 dark:bg-neutral-800">
            <h2 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">Заявки в друзья</h2>
            <ul className="space-y-4">
                {requests.length === 0 ? (
                    <p className="text-gray-600 text-center dark:text-white">Нет заявок в друзья</p>
                ) : (
                    requests.map(request => (
                        <li key={request.id} className="bg-gray-100 p-4 rounded-lg shadow-md flex items-center dark:bg-neutral-700">
                            <div className="flex-1 ">
                                <p className="text-lg font-semibold dark:text-white">{request.sender.name}</p>
                                <p className="text-gray-600 dark:text-gray-300">{request.sender.email}</p>
                            </div>
                            <div className="flex-shrink-0 space-x-2">
                                {width < 768 ? (
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => handleAccept(request.id)}
                                            className="bg-violet-600 text-white w-8 h-8 rounded-lg hover:bg-violet-700 transition duration-200"
                                        >
                                            <i className="fas fa-check"></i> {/* Иконка вместо текста */}
                                        </button>
                                        <button
                                            onClick={() => handleReject(request.id)}
                                            className="bg-red-500 text-white w-8 h-8 rounded-lg hover:bg-red-700 transition duration-200"
                                        >
                                            <i className="fas fa-times"></i> {/* Иконка вместо текста */}
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <button
                                            onClick={() => handleAccept(request.id)}
                                            className="bg-violet-600 text-white px-4 py-2 rounded-lg hover:bg-violet-700 transition duration-200"
                                        >
                                            Принять
                                        </button>
                                        <button
                                            onClick={() => handleReject(request.id)}
                                            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-200"
                                        >
                                            Отклонить
                                        </button>
                                    </>
                                )}
                            </div>
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
}

export default FriendRequests;
