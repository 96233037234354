import React from "react";
import useWindowSize from "../hooks/useWindowSize";

const Loader = () => {
    const { width } = useWindowSize();

    return (
        <div className="flex items-center p-4 bg-gray-100 shadow-inner mb-4 rounded-lg dark:bg-neutral-700 dark:text-white animate-pulse">

            {width < 768 && <i className="fas fa-chevron-left text-xl mr-4 dark:text-neutral-400"></i>}

            <div className="w-10 h-10 rounded-full bg-gray-300 dark:bg-neutral-600 mr-4"></div>

            <div className="flex-1 space-y-2">
                <div className="w-3/4 h-5 bg-gray-300 dark:bg-neutral-600 rounded"></div>
                <div className="w-2/5 h-4 bg-gray-300 dark:bg-neutral-600 rounded"></div>
            </div>

            <div className="w-3 h-6 bg-gray-300 dark:bg-neutral-600 rounded"></div>
        </div>
    );
};

export default Loader;
